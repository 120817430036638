<template>
  <side-bar>
    <template slot="links-after">
      <h6 class="navbar-heading p-0">
        <span class="text">{{ $t('personal') }}</span>
        <span class="icon"><font-awesome-icon icon="fal fa-ellipsis" /></span>
      </h6>

      <ul class="navbar-nav mb-md-4">
        <li class="nav-item">
          <template v-for="link in links">
            <sidebar-item
              v-if="canShow(link.id)"
              :key="link.nameLink"
              :link="link"
            >
              <template v-for="(linkChild, index) in link.children">
                <sidebar-item
                  v-if="canShow(linkChild.id)"
                  :key="index"
                  :link="linkChild"
                />
              </template>
            </sidebar-item>
          </template>
        </li>
      </ul>
    </template>

    <template v-if="shouldShowCompany" slot="links-after">
      <template>
        <h6 class="navbar-heading p-0">
          <span class="text">{{ $t('model.clients.company') }}</span>
          <span class="icon"><font-awesome-icon icon="fal fa-ellipsis" /></span>
        </h6>

        <ul class="navbar-nav mb-md-4 navbar-height">
          <li class="nav-item">
            <template v-for="link in companyLinks">
              <sidebar-item
                v-if="link.visible"
                :key="link.nameLink"
                :link="link"
                @linkClick="onLinkClick"
              >
                <template v-for="(linkChild, index) in link.children">
                  <sidebar-item
                    v-if="canShow(linkChild.id)"
                    :key="index"
                    :link="linkChild"
                  />
                </template>
              </sidebar-item>
            </template>
          </li>
        </ul>
      </template>
    </template>

    <template v-else>
      <ul class="navbar-nav mb-md-4">
        <li class="nav-item">
          <template v-for="link in companyLinks">
            <sidebar-item
              v-if="link.visible"
              :key="link.nameLink"
              :link="link"
            />
          </template>
        </li>
      </ul>
    </template>
  </side-bar>
</template>
<script>
import { find, flatten, pluck, propEq, values } from 'ramda'
import Helper from '@/util/helper'
import ApproveFlow from '@/../frontend-common/dao/ApproveFlow'
import { mapState } from 'vuex'

export default {
  name: 'DashboardNavbarLeft',
  data() {
    const user = this.$store.getters['auth/user']

    if (!user) {
      this.$router.push({ name: 'dang-nhap' })
    }

    const ManageMenu1 = [
      'manage',
      'manage_payroll',
      'manage_register_of_number',
      'manage_debitnote',
      'manage_social-security-claim',
      'quan_ly_bao_cao_pit',
      'manage_thong_ke_luong_phong_ban',
      'manage_nhan_vien',
      'manage_lich_su_luong_chuc_vu',
      'manage_ke_khai_bao_hiem',
      'manage_lich_lam_viec',
      'thong_ke_luong_phong_ban',
    ]

    return {
      project: false,
      clientId: user.client_id,
      employeeId: user.employee_id,
      activeLang: this.$store.getters['lang/locale'],
      timesheetFlows: [],
      approveFlows: [],
      countContract: '',
      permissions: {
        staff: [
          'quan_ly_tin_tuyen_dung',
          'language',
          'timesheet',
          'profile',
          'payroll',
          'register_of_number',
          'request_support',
          'thu_vien_cau_hoi',
          'bang_luong_nhan_vien',
          'leader_timesheet',
          'approve',
          'thiet_lap',
          'quan_ly_nop_ho_so',
          'manage',
          'danh_gia_nhan_su',
          'quan_ly_du_an',
          'yeu_cau_thanh_toan',
          'quan_ly_bao_cao_pit',
          'thong_ke_luong_phong_ban',
          'quan_ly_cong_thuc',
        ],
        accountant: [
          'quan_ly_tin_tuyen_dung',
          'language',
          'timesheet',
          'profile',
          'payroll',
          'register_of_number',
          'request_support',
          'payroll_accountant',
          'thu_vien_cau_hoi',
          'bang_luong_nhan_vien',
          'leader_timesheet',
          'approve',
          'thiet_lap',
          'quan_ly_nop_ho_so',
          'danh_gia_nhan_su',
          'mau_cau_hoi',
          'quan_ly_du_an',
          'yeu_cau_thanh_toan',
          'quan_ly_bao_cao_pit',
          'thong_ke_luong_phong_ban',
          'quan_ly_cong_thuc',
          'mau_dao_tao',
        ],
        manager: [
          'quan_ly_tin_tuyen_dung',
          'language',
          'timesheet',
          'profile',
          'payroll',
          'social_security_manage',
          'register_of_number',
          'request_support',
          'thu_vien_cau_hoi',
          'bang_luong_nhan_vien',
          'leader',
          'leader_timesheet',
          'approve',
          'manage_payroll',
          'thiet_lap',
          'quan_ly_dao_tao',
          'quan_ly_danh_gia',
          'quan_ly_nop_ho_so',
          'quan_ly_hop_dong',
          'danh_gia_nhan_su',
          'mau_cau_hoi',
          'quan_ly_du_an',
          'quan_ly_yeu_cau_thanh_toan',
          'quan_ly_bao_cao_pit',
          'thong_ke_luong_phong_ban',
          'quan_ly_phong_ban',
          'quan_ly_chuc_vu',
          'quan_ly_cong_thuc',
          'mau_dao_tao',
        ].concat(ManageMenu1),
        hr: [
          'quan_ly_tin_tuyen_dung',
          'language',
          'timesheet',
          'profile',
          'payroll',
          'social_security_manage',
          'register_of_number',
          'request_support',
          'thu_vien_cau_hoi',
          'bang_luong_nhan_vien',
          'leader',
          'leader_timesheet',
          'approve',
          'manage_payroll',
          'thiet_lap',
          'quan_ly_dao_tao',
          'quan_ly_danh_gia',
          'quan_ly_nop_ho_so',
          'danh_gia_nhan_su',
          'mau_cau_hoi',
          'quan_ly_du_an',
          'yeu_cau_thanh_toan',
          'quan_ly_bao_cao_pit',
          'thong_ke_luong_phong_ban',
          'quan_ly_cong_thuc',
          'mau_dao_tao',
        ].concat(ManageMenu1),
        leader: [
          'quan_ly_tin_tuyen_dung',
          'language',
          'timesheet',
          'profile',
          'payroll',
          'register_of_number',
          'request_support',
          'leader',
          'thu_vien_cau_hoi',
          'leader_timesheet',
          'bang_luong_nhan_vien',
          'approve',
          'thiet_lap',
          'quan_ly_dao_tao',
          'quan_ly_danh_gia',
          'quan_ly_nop_ho_so',
          'danh_gia_nhan_su',
          'mau_cau_hoi',
          'quan_ly_du_an',
          'yeu_cau_thanh_toan',
          'quan_ly_bao_cao_pit',
          'thong_ke_luong_phong_ban',
          'quan_ly_cong_thuc',
          'mau_dao_tao',
        ].concat(ManageMenu1),
      },
      userPermissions: this.$store.state.auth.permissions,
      clientWorkflowSettings: this.$store.state.auth.clientWorkflowSetting,
      clientData: this.$store.state.auth.client,
      unreadTotal: 0,
      ids: []
    }
  },
  computed: {
    shouldShowCompany() {
      return this.companyLinks.find((v) => v.visible)
    },
    companyLinks() {
      return this.links1.map((v) => {
        if (v.id === 'thiet_lap') {
          const manageWifiCheckin =
            this.userCan('manage-wifi-checkin') &&
            this.clientWorkflowSettings.enable_wifi_checkin
          const manageCameraCheckin =
            this.userCan('manage-camera-checkin') &&
            this.clientWorkflowSettings.enable_wifi_checkin
          const manageWorkschedule = this.userCan('manage-workschedule')
          const manageLocationCheckin =
            this.userCan('manage-location-checkin') &&
            this.clientWorkflowSettings.enable_location_checkin

          let path = false

          if (manageWifiCheckin) {
            path = '/wifi-checkin-spot'
          } else if (manageCameraCheckin) {
            path = '/hanet-ai'
          } else if (manageWorkschedule) {
            path = '/quan-ly-lich-lam-viec'
          } else if (manageLocationCheckin) {
            path = '/location-checkin'
          }

          if (path !== false) {
            v.path = path
          }
        }

        if (v.id === 'quan_ly_dao_tao') {
          const manageTraining = this.userCan('manage-training')

          let path = false

          if (
            manageTraining &&
            this.clientWorkflowSettings.enable_training_seminar
          ) {
            path = '/quan-ly-dao-tao'
          }

          if (path !== false) {
            v.path = path
          }
        }

        if (v.id === 'quan_ly_danh_gia') {
          const manageEvaluation = this.userCan('manage-evaluation')

          let path = false

          if (
            manageEvaluation &&
            this.clientWorkflowSettings.enable_evaluate
          ) {
            path = '/quan-ly-danh-gia'
          } else {
            path = '/danh-gia-nhan-su'
          }

          if (path !== false) {
            v.path = path
          }
        }

        if (v.id === 'quan_ly_nhanvien') {
          if(this.clientWorkflowSettings.advanced_permission_flow){
            const manageEmployeeList = this.userCan('advanced-manage-employee-list-read')
            const manageEmployeeGroup = this.userCan('advanced-manage-employee-group-read')
            const manageEmployeeHistory = this.userCan('advanced-manage-employee-history-position-read')
            const manageAccount = this.user.role === 'manager'
            const clientType = this.clientData.client_type

            let path = false

            if (manageEmployeeList) {
              path = '/nhan-vien'
            } else if(manageEmployeeGroup){
              path = '/nhom-nhan-vien'
            } else if(manageEmployeeHistory){
              path = '/lich-su-chuc-vu'
            } else if(manageAccount && clientType === 'system'){
              path = '/quan-ly-tai-khoan'
            }

            if (path !== false) {
              v.path = path
            }
          } else {
            const manageEmployee = this.userCan('manage-employee')
            const manageEmployeeGroup = this.userCan('manage-employee')
            const manageEmployeeHistory = this.userCan('manage-employee')
            const manageAccount = this.user.role === 'manager'
            const clientType = this.clientData.client_type

            let path = false

            if (manageEmployee) {
              path = '/nhan-vien'
            } else if(manageEmployeeGroup){
              path = '/nhom-nhan-vien'
            } else if(manageEmployeeHistory){
              path = '/lich-su-chuc-vu'
            } else if(manageAccount && clientType === 'system'){
              path = '/quan-ly-tai-khoan'
            }

            if (path !== false) {
              v.path = path
            }
          }
        }

        if (v.id === 'quan_ly_bang_luong_quan_ly') {
          if(this.clientWorkflowSettings.advanced_permission_flow){
            const managePayroll = this.userCan('advanced-manage-payroll-info-read') && this.userCan('advanced-manage-payroll-list-read')
            const manageSocialSecurity = this.userCan('advanced-manage-payroll-social-insurance-read')
            const manageSocialClaim = this.userCan('advanced-manage-payroll-social-declaration-read')
            const manageSalaryHistory =
              this.userCan('advanced-manage-payroll-info-read') && this.userCan('advanced-manage-payroll-salary-history-read')

            let path = false

            if (managePayroll) {
              path = '/quan-ly-bang-luong-quan-ly'
            } else if(manageSocialSecurity){
              path = '/quan-ly-social-security-claim'
            } else if (manageSocialClaim) {
              path = '/quan-ly-ke-khai-bao-hiem'
            } else if(manageSalaryHistory){
              path = '/lich-su-luong'
            }

            if (path !== false) {
              v.path = path
            }
          } else {
            const managePayroll = this.userCan('manage-payroll')
            const manageSocial = this.userCan('manage-social')
            const manageDebitNote = this.userCan('manage-debit-note')
            const manageKeKhaiBaoHiem = this.userCan(
              'CLIENT_REQUEST_SOCIAL_SECURITY_PROFILE'
            )
            const manageSocialClaim = this.userCan('CLIENT_REQUEST_CLAIM_BHXH')
          const managePayrollComplaint = this.userCan('manage-payroll-complaint')

            let path = false

            if (managePayroll) {
              path = '/quan-ly-bang-luong-quan-ly'
            } else if (
              manageSocial ||
              (manageKeKhaiBaoHiem && manageSocialClaim)
            ) {
              path = '/quan-ly-social-security-claim'
            } else if (manageDebitNote) {
              path = '/quan-ly-debitnote'
            } else if (manageKeKhaiBaoHiem) {
              path = '/quan-ly-ke-khai-bao-hiem'
            } else if (manageSocialClaim) {
              path = '/quan-ly-social-security-claim'
            } else if(managePayrollComplaint){
            path = '/quan-ly-khieu-nai-bang-luong'
          }

            if (path !== false) {
              v.path = path
            }
          }
        }

        if (v.id === 'create_payroll') {
          const createPayroll =
            this.clientWorkflowSettings.enable_create_payroll &&
            this.userCan('manage-payroll')
          const manageFormula =
            this.userCan('manage-formula') &&
            this.clientWorkflowSettings.enable_create_payroll

          let path = false

          if (createPayroll) {
            path = '/bang-luong/tao-moi'
          } else if (manageFormula) {
            path = '/quan-ly-cong-thuc'
          }

          if (path !== false) {
            v.path = path
          }
        }

        if (v.id === 'quan_ly_timesheet') {
          if(this.clientWorkflowSettings.advanced_permission_flow){
            const manageTimesheetSummary = this.userCan('advanced-manage-timesheet-summary-read')

            const manageTimesheet =
              this.userCan('advanced-manage-timesheet-working-read')

            const manageTimesheetShift = this.userCan('advanced-manage-timesheet-timesheet-shift-read')
            const manageDKCSLeave =
              this.userCan('advanced-manage-timesheet-leave-read')

            const manageDKCSOT =
              this.userCan('advanced-manage-timesheet-overtime-read')

            const manageWFH =
              this.userCan('advanced-manage-timesheet-outside-working-wfh-read')

            const manageAdjustHour = this.userCan('advanced-manage-timesheet-adjust-hours-read')

            let path = false
            if (manageTimesheetSummary) {
              path = '/quan-ly-dang-ky-cong-so-tong-hop'
            } else if(manageTimesheet){
              path = '/quan-ly-timesheet'
            } else if(manageTimesheetShift){
              path = '/quan-ly-danh-sach-ca'
            } else if (manageDKCSLeave) {
              path = '/quan-ly-dang-ky-cong-so-leave'
            } else if (manageDKCSOT) {
              path = '/quan-ly-dang-ky-cong-so-ot'
            } else if (manageWFH) {
              path = '/quan-ly-dang-ky-cong-so-cong-tac'
            } else if (manageAdjustHour){
              path = '/quan-ly-dieu-chinh-gio-lam-thuc-te'
            }

            if (path !== false) {
              v.path = path
            }
          }
          else{
            const manageTimesheet =
              this.userCan('manage-timesheet') ||
              this.userCan('CLIENT_REQUEST_TIMESHEET')
            const manageDKCSLeave =
              this.userCan('CLIENT_REQUEST_OFF') &&
              this.clientWorkflowSettings.enable_leave_request
            const manageDKCSOT =
              (this.userCan('CLIENT_REQUEST_OT') || this.userCan('CLIENT_REQUEST_OT_ASSIGNMENT')) &&
              this.clientWorkflowSettings.enable_overtime_request
            let path = false

            if (manageTimesheet) {
              path = '/quan-ly-timesheet'
            } else if (manageDKCSLeave) {
              path = '/quan-ly-dang-ky-cong-so-leave'
            } else if (manageDKCSOT) {
              path = '/quan-ly-dang-ky-cong-so-ot'
            }

            if (path !== false) {
              v.path = path
            }
          }
        }

        return {
          ...v,
          visible: this.canShow(v.id),
        }
      })
    },
    canShowManageTimesheetMenu() {
      return false
    },
    assignedCount() {
      return this.user ? this.user.assigned_approves_count : 0
    },
    getNameRecruitContract(){
      return this.clientWorkflowSettings.enable_recruit_function &&
              (this.manageJobboardPermission && !this.userCan('manage-contract')) ? this.$t('model.jobboard_job.title') :
              this.clientWorkflowSettings.enable_recruit_function && (!this.manageJobboardPermission && this.userCan('manage-contract')) ? this.$t('contract') :
              this.$t('recruitment_contract');
    },
    relocateRouter(){
      if(this.manageJobboardPermission){
        return '/quan-ly-tin-tuyen-dung';
      } else{
        return '/quan-ly-hop-dong';
      }
    },
    getNotification() {
      Helper.gqlQuery("Notification", "get", {
        unread: 1,
        limit: 100,
        client_id: this.clientId,
        type_notification: 'AppliedDocumentNotification'
      }).then(({ data }) => {
        const notifications = JSON.parse(data.notifications)
        let notifications_values = values(notifications.data)
        this.unreadTotal = notifications.unread_total
        for(let i = 0; i<= notifications_values.length; i++){
          if(notifications_values[i]){
            this.ids.push(notifications_values[i].id)
          }
        }
      })
      return this.unreadTotal
    },
    links() {
      return [
        {
          id: 'profile',
          name: this.$t('model.clients.profile'),
          icon: 'fal fa-info-circle',
          path: '/ho-so-ca-nhan/thong-tin-co-ban',
        },
        {
          id: 'timesheet',
          name: this.$t('working_leave'),
          icon: 'fal fa-business-time',
          path: '/time-sheet',
        },
        {
          id: 'bang_luong_nhan_vien',
          name: this.$t('salary_insurance'),
          icon: 'fal fa-badge-dollar',
          path: '/bang-luong-nhan-vien',
        },
        {
          id: 'dao_tao',
          name: this.$t('model.evaluation.training'),
          icon: 'fal fa-user-graduate',
          path: '/dao-tao',
        },
        // {
        //   id: 'danh_gia',
        //   name: this.$t('model.evaluation.make_evaluation'),
        //   icon: 'fal fa-file-certificate',
        //   path: '/danh-gia',
        // },
        {
          id: 'danh_gia_ban_than',
          name: this.$t('model.evaluation.make_evaluation'),
          icon: 'fal fa-file-certificate',
          path: '/danh-gia-ban-than',
        },
        // {
        //   id: 'quan_ly_du_an_cua_toi',
        //   name: this.$t('model.project.myproject'),
        //   icon: '/img/icons/my-projects.svg',
        //   path: '/quan-ly-du-an-cua-toi',
        // },
        {
          id: 'yeu_cau_thanh_toan',
          name: this.$t('model.payment_request.title'),
          icon: 'fal fa-money-check-edit-alt',
          path: '/yeu-cau-thanh-toan',
        },
        {
          id: 'hop_dong',
          name: this.$t('contract'),
          icon: 'fal fa-file-certificate',
          path: '/hop-dong-lao-dong',
        },
      ]
    },
    links1() {
      return [
        {
          id: 'approve',
          name: this.$t('model.buttons.approved_request'),
          icon: 'fal fa-clipboard-check',
          path: '/yeu-cau-duyet',
          badge: this.assignedCount,
        },
        {
          id: 'quan_ly_nhanvien',
          name: this.$t('model.employees.list'),
          icon: 'fal fa-users',
          path: '/nhan-vien',
        },
        {
          id: 'quan_ly_timesheet',
          name: this.$t('working_leave'),
          icon: 'fa-light fa-business-time',
          path: '/quan-ly-dang-ky-cong-so-tong-hop',
        },
        {
          id: 'quan_ly_bang_luong_quan_ly',
          name: this.$t('salary_insurance'),
          icon: 'fal fa-badge-dollar',
          path: '/quan-ly-bang-luong-quan-ly',
        },
        {
          id: 'thong_ke_luong_phong_ban',
          name: this.$t('report'),
          icon: 'fa-light fa-chart-pie',
          path: '/bao-cao-tong-hop',
        },
        {
          id: 'quan_ly_tin_tuyen_dung',
          name: this.getNameRecruitContract,
          icon: 'fa-light fa-user-plus',
          path: this.relocateRouter
        },
        {
          id: 'quan_ly_dao_tao',
          name: this.$t('model.evaluation.training'),
          icon: 'fa-light fa-user-graduate',
          path: '/quan-ly-dao-tao',
        },
        {
          id: 'quan_ly_danh_gia',
          name: this.$t('model.evaluation.make_evaluation'),
          icon: 'fa-light fa-file-certificate',
          path: '/quan-ly-danh-gia',
        },
        {
          id: 'quan_ly_phan_quyen',
          name: this.$t('model.clients.flows_permissions_manager'),
          icon: 'fa-light fa-user-lock',
          path: '/quan-ly-phan-quyen/thiet-lap-flow',
        },
        {
          id: 'quan_ly_nop_ho_so',
          name: this.$t('model.client_applied_document.title'),
          icon: 'fa-light fa-address-book',
          path: '/quan-ly-nop-ho-so',
          badge: this.getNotification,
        },
        {
          id: 'quan_ly_du_an',
          name: this.$t('model.project.title'),
          icon: 'fa-light fa-diagram-project',
          path: '/quan-ly-du-an',
        },
        {
          id: 'quan_ly_yeu_cau_thanh_toan',
          name: this.$t('model.payment_request.title'),
          icon: 'fa-light fa-money-check-dollar-pen',
          path: '/quan-ly-yeu-cau-thanh-toan',
        },
        {
          id: 'thiet_lap',
          name: this.$t('settings'),
          icon: 'fa-light fa-gear',
          path: '/wifi-checkin-spot',
        },
        {
          id: 'create_payroll',
          name: this.$t('payroll_design'),
          icon: 'fa-light fa-list-check',
          path: '/bang-luong/tao-moi',
        },
        // {
        //   id: 'quan_ly_cong_thuc',
        //   name: this.$t('recipe_management'),
        //   icon: 'fa-light fa-list-check',
        //   path: '/quan-ly-cong-thuc',
        // },
        // {
        //   id: 'create_sample_payroll',
        //   name: this.$t('model.salary_test.tittle'),
        //   icon: '/img/icons/icon3.png',
        //   path: '/bang-luong/tao-bang-tinh-luong-thu',
        // },
        // {
        //   id: 'create_payroll',
        //   name: this.$t('model.payroll.sample_payroll'),
        //   icon: '/img/icons/icon3.png',
        //   path: '/quan-ly-mau-tinh-luong'
        // },
        // {
        //   id: 'create_payroll',
        //   name: this.$t('model.payroll.sample_payroll_template'),
        //   icon: '/img/icons/icon3.png',
        //   path: '/quan-ly-template-bang-luong'
        // },
        // {
        //   id: 'create_payroll',
        //   name: this.$t('model.clients.accounting_report_form'),
        //   icon: '/img/icons/icon3.png',
        //   path: '/quan-ly-mau-bao-cao-ke-toan'
        // },
        // {
        //   id: 'create_payroll',
        //   name: this.$t('model.formulas.variable'),
        //   icon: '/img/icons/icon8.png',
        //   path: '/quan-ly-dinh-nghia-bien'
        // },
        // {
        //   id: 'manage_lich_lam_viec',
        //   name: this.$t('config'),
        //   icon: '/img/icons/icon2.png',
        //   path: '/quan-ly-lich-lam-viec'
        // },
        {
          id: 'camera_device',
          name: this.$t('model.camera_device.menu_title'),
          icon: '../img/icons/icon4.png',
          path: '/camera-device',
        },
      ]
    },
    dkcsPermission() {
      return (
        this.authPermissions.includes('flow_client_request_off') ||
        this.authPermissions.includes('flow_client_request_ot')
      )
    },
    ...mapState({
      user: (state) => state.auth.user,
      authPermissions: (state) => state.auth.permissions,
      hasJobboardAssignment: (state) => state.auth.hasJobboardAssignment,
      hasEvaluationAssignment: (state) => state.auth.hasEvaluationAssignment,
    }),
    manageJobboardPermission() {
      return (
        this.authPermissions.includes('manage-jobboard') ||
        this.hasJobboardAssignment.length > 0
      )
    },
    manageEvaluationPermission() {
      const manageEvaluation = this.userCan('manage-evaluation')
      return (
        manageEvaluation ||
        this.hasEvaluationAssignment?.length > 0
      )
    },
  },
  mounted() {
    this.loadJobboardAssignment()
    this.loadEvaluationAssignment()
    this.checkContract()
    if (
      this.clientWorkflowSettings &&
      this.clientWorkflowSettings.enable_client_project
    ) {
      this.getTimeLog()
    }
  },
  methods: {
    canShow(linkID) {
      let canShow =
        this.user && this.permissions[this.user.role]
          ? this.permissions[this.user.role].includes(linkID)
          : false

      if (linkID === 'approve') {
        return (
          this.authPermissions.includes('CLIENT_REQUEST_PAYROLL') ||
          this.authPermissions.includes('CLIENT_UPDATE_EMPLOYEE_OTHERS') ||
          this.authPermissions.includes('CLIENT_UPDATE_EMPLOYEE_BASIC') ||
          this.authPermissions.includes('CLIENT_UPDATE_EMPLOYEE_PAYROLL') ||
          this.authPermissions.includes('CLIENT_UPDATE_DEPENDENT') ||
          this.authPermissions.includes(
            'CLIENT_REQUEST_TIMESHEET_EDIT_WORK_HOUR'
          ) ||
          this.authPermissions.includes('CLIENT_REQUEST_CLAIM_BHXH') ||
          this.authPermissions.includes('CLIENT_REQUEST_TIMESHEET') ||
          this.authPermissions.includes('CLIENT_REQUEST_OT') ||
          this.authPermissions.includes('CLIENT_REQUEST_OT_ASSIGNMENT') ||
          this.authPermissions.includes('CLIENT_REQUEST_OFF') ||
          this.authPermissions.includes('CLIENT_REQUEST_EARLY_LEAVE') ||
          this.authPermissions.includes('CLIENT_REQUEST_CONG_TAC')||
          this.authPermissions.includes('CLIENT_REQUEST_TIMESHEET_SHIFT') ||
          this.authPermissions.includes('CLIENT_REQUEST_ROAD_TRANSPORTATION') ||
          this.authPermissions.includes('CLIENT_REQUEST_AIRLINE_TRANSPORTATION') ||
          this.authPermissions.includes('CLIENT_REQUEST_PAYMENT')
        )
      }

      if (linkID === 'timesheet') {
        if (
          !this.clientWorkflowSettings.enable_leave_request &&
          !this.clientWorkflowSettings.enable_overtime_request
        ) {
          canShow = false
        }
      }

      // TODO add "enable_request_payment" config
      if (linkID === 'yeu_cau_thanh_toan') {
        if (!this.clientWorkflowSettings.enable_request_payment) {
          return false
        }
      }

      if (linkID === 'quan_ly_bang_luong_quan_ly') {
        if(this.clientWorkflowSettings.advanced_permission_flow){
          const managePayroll = this.userCan('advanced-manage-payroll-info-read') && this.userCan('advanced-manage-payroll-list-read')
          const manageSocialSecurity = this.userCan('advanced-manage-payroll-social-insurance-read')
          const manageSocialClaim = this.userCan('advanced-manage-payroll-social-declaration-read')
          const manageSalaryHistory =
           this.userCan('advanced-manage-payroll-info-read') && this.userCan('advanced-manage-payroll-salary-history-read')

          return (
            managePayroll ||
            manageSocialSecurity ||
            manageSocialClaim ||
            manageSalaryHistory
          )
        } else {
          const managePayroll = this.userCan('manage-payroll')
          const manageSocial = this.userCan('manage-social')
          const manageKeKhaiBaoHiem = this.userCan(
            'CLIENT_REQUEST_SOCIAL_SECURITY_PROFILE'
          )
          const manageSocialClaim = this.userCan('CLIENT_REQUEST_CLAIM_BHXH')
          const manageDebitNote = this.userCan('manage-debit-note')
        const managePayrollComplaint = this.userCan('manage-payroll-complaint')

        return (
          managePayroll ||
          manageSocial ||
          manageDebitNote ||
          manageKeKhaiBaoHiem ||
          manageSocialClaim ||
          managePayrollComplaint
        )}
      }

      if (linkID === 'hop_dong') {
        // TODO there is not option to turned off, always enabled - 1
        // TODO This is now will show by enable_recruit_function and length of list > 0
        if(this.clientWorkflowSettings.enable_recruit_function){
          if(this.countContract === 0 || this.countContract === ''){
            return false
          } else {
            return true
          }
        } else{
          return false
        }
      }

      if (linkID === 'quan_ly_bao_cao_pit') {
        return this.userCan('manage-payroll')
      }

      if (linkID === 'thong_ke_luong_phong_ban') {
        return this.userCan('manage-payroll')
      }

      if (linkID === 'quan_ly_cong_thuc') {
        return this.userCan('manage-formula')
      }

      if (linkID === 'quan_ly_timesheet') {
        if(this.clientWorkflowSettings.advanced_permission_flow){
          const manageTimesheetSummary =
            this.userCan('advanced-manage-timesheet-summary-read')

          const manageTimesheet =
            this.userCan('advanced-manage-timesheet-working-read')

          const manageTimesheetShift = this.userCan('advanced-manage-timesheet-timesheet-shift-read')
          const manageDKCSLeave =
            this.userCan('advanced-manage-timesheet-leave-read')

          const manageDKCSOT =
            this.userCan('advanced-manage-timesheet-overtime-read')

          const manageWFH =
            this.userCan('advanced-manage-timesheet-outside-working-wfh-read')

          const manageAdjustHour = this.userCan('advanced-manage-timesheet-adjust-hours-read')
          let path = false

          if (manageTimesheetSummary) {
            path = '/quan-ly-dang-ky-cong-so-tong-hop'
          } else if(manageTimesheet){
            path = '/quan-ly-timesheet'
          } else if(manageTimesheetShift){
            path = '/quan-ly-danh-sach-ca'
          } else if (manageDKCSLeave) {
            path = '/quan-ly-dang-ky-cong-so-leave'
          } else if (manageDKCSOT) {
            path = '/quan-ly-dang-ky-cong-so-ot'
          } else if (manageWFH) {
            path = '/quan-ly-dang-ky-cong-so-cong-tac'
          } else if(manageAdjustHour){
            path = '/quan-ly-dieu-chinh-gio-lam-thuc-te'
          }

          if (path !== false) {
            const link = find(propEq('id', 'quan_ly_timesheet'))(this.links1)

            link.path = path
          }

          return path !== false
        } else {
          const manageTimesheet =
            this.userCan('manage-timesheet') ||
            this.userCan('CLIENT_REQUEST_TIMESHEET')
          const manageDKCSLeave =
            this.userCan('CLIENT_REQUEST_OFF') &&
            this.clientWorkflowSettings.enable_leave_request
          const manageDKCSOT =
            (this.userCan('CLIENT_REQUEST_OT') || this.userCan('CLIENT_REQUEST_OT_ASSIGNMENT')) &&
            this.clientWorkflowSettings.enable_overtime_request
          let path = false

          if (manageTimesheet) {
            path = '/quan-ly-dang-ky-cong-so-tong-hop'
          } else if (manageDKCSLeave) {
            path = '/quan-ly-dang-ky-cong-so-leave'
          } else if (manageDKCSOT) {
            path = '/quan-ly-dang-ky-cong-so-ot'
          }

          if (path !== false) {
            const link = find(propEq('id', 'quan_ly_timesheet'))(this.links1)

            link.path = path
          }

          return path !== false
        }

      }

      if (linkID === 'social_security_manage') {
        if (!this.clientWorkflowSettings.enable_social_security_manage) {
          canShow = false
        }
      }

      if (['timesheet', 'leader_timesheet'].includes(linkID)) {
        if (!this.clientWorkflowSettings.show_timesheet_for_customer) {
          canShow = false
        }
      }

      if (linkID === 'quan_ly_dao_tao') {
        const manageTraining = this.userCan('manage-training')

        return (
          this.clientWorkflowSettings.enable_training_seminar && manageTraining
        )
      }

      if (linkID === 'quan_ly_danh_gia') {
        return this.clientWorkflowSettings.enable_evaluate && this.manageEvaluationPermission
      }

      if (linkID === 'quan_ly_phan_quyen') {
        return (
          this.clientWorkflowSettings.enable_setting_flow_permission &&
          this.user.role === 'manager'
        )
      }
      if (linkID === 'quan_ly_nhanvien') {
        if(this.clientWorkflowSettings.advanced_permission_flow){
          const manageEmployeeList = this.userCan('advanced-manage-employee-list-read')
          const manageEmployeeGroup = this.userCan('advanced-manage-employee-group-read')
          const manageEmployeeHistory = this.userCan('advanced-manage-employee-history-position-read')
          const manageAccount = this.user.role === 'manager'
          const clientType = this.clientData.client_type

          let path = false

          if (manageEmployeeList) {
            path = '/nhan-vien'
          } else if(manageEmployeeGroup){
            path = '/nhom-nhan-vien'
          } else if(manageEmployeeHistory){
            path = '/lich-su-chuc-vu'
          } else if(manageAccount && clientType === 'system'){
            path = '/quan-ly-tai-khoan'
          }

          if (path !== false) {
            const link = find(propEq('id', 'quan_ly_nhanvien'))(this.links1)

            link.path = path
          }

          return path !== false
        } else {
          return this.userCan('manage-employee')
        }
      }

      if (linkID === 'manage_ke_khai_bao_hiem') {
        return this.userCan('manage-social')
      }

      if (linkID === 'manage_register_of_number') {
        canShow =
          this.dkcsPermission &&
          (this.clientWorkflowSettings.enable_leave_request ||
            this.clientWorkflowSettings.enable_overtime_request)
      }

      if (linkID === 'manage_timesheet') {
        return (
          this.userCan('manage-timesheet') ||
          this.userCan('CLIENT_REQUEST_TIMESHEET')
        )
      }

      if (linkID === 'quan_ly_tin_tuyen_dung') {
        canShow =
          this.clientWorkflowSettings.enable_recruit_function &&
          (this.manageJobboardPermission || (this.userCan('manage-contract')))
      }

      if (linkID === 'quan_ly_hop_dong') {
        return (this.userCan('manage-contract'))
      }

      if (linkID === 'create_payroll') {
        const createPayroll =
          this.clientWorkflowSettings.enable_create_payroll &&
          this.userCan('manage-payroll')
        const manageFormula =
          this.userCan('manage-formula') &&
          this.clientWorkflowSettings.enable_create_payroll
        // const

        let path = false

        if (createPayroll) {
          path = '/bang-luong/tao-moi'
        } else if (manageFormula) {
          path = '/quan-ly-cong-thuc'
        }

        if (path !== false) {
          const link = find(propEq('id', 'create_payroll'))(this.links1)
          link.path = path
        }

        return path !== false
      }

      if (linkID === 'create_sample_payroll') {
        return this.userCan('permission_sample_payroll')
      }

      if (linkID === 'manage_lich_lam_viec') {
        return this.userCan('manage-workschedule')
      }

      if (linkID === 'quan_ly_nop_ho_so') {
        return this.userCan('permission_apply_document')
      }

      if (linkID === 'quan_ly_du_an') {
        return (
          this.userCan('manage-project') &&
          this.clientWorkflowSettings.enable_client_project
        )
      }

      if (linkID === 'yeu_cau_thanh_toan') {
        return true
      }

      if (linkID === 'quan_ly_yeu_cau_thanh_toan') {
        return (
          this.userPermissions.includes('manage-payment-request') &&
          this.clientWorkflowSettings.enable_request_payment
        )
      }

      if (linkID === 'camera_device') {
        // TODO tam an tren production
        return false
      }

      // if (linkID === 'danh_gia_ban_than') {
      //   //Fix bug: duplicate route - Only show if training seminar is not displayed
      //   return (
      //     this.user &&
      //     this.user.selfEvaluation &&
      //     !this.clientWorkflowSettings.enable_training_seminar
      //   )
      //   // return this.clientWorkflowSettings.enable_training_seminar
      // }

      if (linkID === 'quan_ly_du_an_cua_toi') {
        return this.project && this.clientWorkflowSettings.enable_client_project
      }

      if (linkID === 'dao_tao') {
        return this.clientWorkflowSettings.enable_training_seminar
        // return this.user && this.user.selfEvaluation
      }

      if (linkID === 'danh_gia_ban_than') {
        return this.clientWorkflowSettings.enable_evaluate
      }

      if (linkID === 'danh_gia_nhan_su') {
        return (
          this.activeLang === 'vi' && this.user && this.user.otherEvaluation
        )
      }

      if (linkID === 'hanet-ai') {
        return true
      }

      if (linkID === 'thiet_lap') {
        const manageWifiCheckin =
          this.userCan('manage-wifi-checkin') &&
          this.clientWorkflowSettings.enable_wifi_checkin
        const manageCameraCheckin =
          this.userCan('manage-camera-checkin') &&
          this.clientWorkflowSettings.enable_wifi_checkin
        const manageWorkschedule = this.userCan('manage-workschedule')
        const manageLocationCheckin =
          this.userCan('manage-location-checkin') &&
          this.clientWorkflowSettings.enable_location_checkin
        return manageWifiCheckin || manageCameraCheckin || manageWorkschedule || manageLocationCheckin
      }

      return canShow
    },
    changeLocale(lang) {
      Helper.gqlMutate('Auth', 'setPreferences', {
        id: this.$store.getters['auth/user'].id,
        preferedLanguage: lang,
      }).then(() => {
        window.location.reload()
      })
    },
    loadJobboardAssignment() {
      return new Promise((resolve, reject) => {
        Helper.gqlQuery('JobboardAssignment', 'get', {
          perPage: 1000,
          page: 1,
          where: {
            AND: [
              { column: 'CLIENT_ID', operator: 'EQ', value: this.clientId },
              {
                column: 'CLIENT_EMPLOYEE_ID',
                operator: 'EQ',
                value: this.employeeId,
              },
            ],
          },
        }).then(({ data }) => {
          const jobs = pluck('jobboard_job_id', data.jobboardAssignments.data)

          this.$store.commit('auth/HAS_JOBBOARD_ASSIGNMENT', jobs)
          resolve()
        })
      })
    },
    loadApproveFlows() {
      return new Promise((resolve, reject) => {
        ApproveFlow.getAll(this.user.client_id)
          .then((flows) => {
            resolve(flows)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    userCan(permission) {
      return this.authPermissions.includes(permission)
    },
    userInFlow(flowName) {
      const userFlows = flatten(
        pluck('approveFlowUsers', values(this.approveFlows[flowName]))
      )

      let isInFlow = false

      userFlows.forEach((f) => {
        isInFlow = isInFlow || f.user.id === this.user.id
      })

      return isInFlow
    },
    loadApprove(flowName) {
      return new Promise((resolve, reject) => {
        ApproveFlow.getApproveFlows(this.user.client_id, flowName)
          .then((flows) => {
            resolve(flows)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getTimeLog() {
      const orderby = this.sortBy ? this.sortBy.toUpperCase() : 'START_TIME'
      const order = this.sortDesc ? 'DESC' : 'ASC'
      const where = []
      Helper.gqlQuery('ClientProject', 'getNotPaginate', {
        orderBy: [{ field: orderby, order }],
        where: {
          AND: where,
        },
        hasClientProjectEmployee: {
          column: 'CLIENT_EMPLOYEE_ID',
          operator: 'EQ',
          value: this.user.employee_id,
        },
      }).then((data) => {
        this.project = data.data.clientProjectsNotPaginate.length > 0
      })
    },
    checkContract() {
      const orderby = this.sortBy ? this.sortBy.toUpperCase() : 'CREATED_AT'
      const order = this.sortDesc ? 'DESC' : 'ASC'

      let where = [
        {column: 'CLIENT_ID', operator: 'EQ', value: this.clientId},
        {column: 'CLIENT_EMPLOYEE_ID', operator: 'EQ', value: this.user.clientEmployee.id},
      ]

      Helper.gqlQuery('Contract', 'get', {
        perPage: 1000,
        page: 1,
        orderBy: [{field: orderby, order: order}],
        where: {
          AND: where
        }
      }).then(({data}) => {
        const pagination = data.contracts.paginatorInfo
        this.countContract = pagination.total
      })
    },
    onLinkClick(){
      if(this.ids && this.ids.length.length !== 0){
        Helper.gqlMutate("Notification", "markRead", {
          ids: this.ids,
        }).then(({ data }) => {
          if (data) {
            this.unreadTotal = 0
          }
        })
      }
    },
    loadEvaluationAssignment() {
      return new Promise((resolve, reject) => {
        Helper.gqlQuery('EvaluationParticipant', 'evaluationParticipants', {
          perPage: 1000,
          page: 1,
          where: {
            AND: [
              {
                column: 'IS_SKIPED',
                operator: 'EQ',
                value: false,
              },
              {
                column: 'CLIENT_EMPLOYEE_ID',
                operator: 'EQ',
                value: this.employeeId,
              },
            ],
          },
        }).then(({ data }) => {
          const evaluations = pluck('id', data.evaluationParticipants.data)

          this.$store.commit('auth/HAS_EVALUATION_ASSIGNMENT', evaluations)
          resolve()
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.navbar-heading {
  font-size: 13px;
  text-transform: inherit;
  color: #686868;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  background: rgba(241, 241, 241, 1);

  font-family: Roboto;
  font-weight: 400;

  span {
    padding: 5px 1.5rem;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    margin-inline-end: auto;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.icon {
      width: 50px;
      height: auto;
      display: none;

      svg {
        padding-top: 3px;
      padding-bottom: 3px;
        font-size: 28px;
      }
    }
  }
}

.navbar-height {
  padding-bottom: 50px;
  height: calc(100% - 50px);
  //overflow-y: auto;
  position: relative;
}

.other-navbar-nav {
  position: absolute;
  bottom: 0;
  left: 24px;
  right: 24px;
  width: calc(100% - 50px);
  .nav-item {
    list-style: none;
    margin-bottom: 8px;
    margin-left: -12px;
    margin-right: -12px;
    .nav-link {
      border: 1px solid rgba(226, 226, 226, 1);
      border-radius: 4px;
      color: #707070;
      font-family: Roboto;
      font-weight: 300;
      display: flex;
    }
  }
}
</style>
