<template>
  <div>
    <ValidationProvider
      :vid="name"
      :name="name"
      :rules="rules"
      v-slot="{ errors }"
      :tag="templateTag"
    >
      <div
        class="form-group"
        :class="isFlex ? 'd-flex flex-1 flex-column' : ''"
        :style="noMarginBottom ? 'margin-bottom: 0;' : ''"
      >
        <label
          v-if="label"
          class="form-control-label"
          :class="[isFlex ? 'd-flex flex-1 flex-column' : '', labelClasses]"
          ><strong v-if="required" class="text-danger">*</strong>
          {{ label }}</label
        >
        <div class="has-label">
          <!-- only show when have enable_timesheet_min_time_block and isRange is false because el-time-select doesn't support is-range -->
          <el-time-select
            v-if="
              (clientWorkflowSetting &&
                clientWorkflowSetting.enable_timesheet_min_time_block &&
                !isRange) ||
              timeSelectFlag
            "
            v-model="valueData"
            :value-format="valueFormat"
            :placeholder="placeholder"
            :picker-options="{
              step: timesheetMinTimeBlock,
              start: startTime,
              end: endTime,
            }"
            :disabled="disabled"
            :format="format"
            :clearable="clearable"
            :required="required"
            v-bind="$attrs"
            :class="[
            !showErrorText && errors[0]
                ? 'border border-danger border-radius-4px'
                : '',
            ]"
            @change="onChange"
          />
          <el-time-picker
            v-else
            :is-range="isRange"
            :range-separator="$t('range_time')"
            v-model="valueData"
            :value-format="valueFormat"
            :placeholder="placeholder"
            :disabled="disabled"
            :format="format"
            :clearable="clearable"
            :required="required"
            v-bind="$attrs"
            :class="[
            !showErrorText && errors[0]
                ? 'border border-danger border-radius-4px'
                : '',
            ]"
            @change="onChange"
          />
        </div>
        <div v-if="errors[0] && showErrorText" class="mt-2">
          <small class="text-danger">{{ errors[0] }}</small>
        </div>
      </div>
    </ValidationProvider>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'

function convertToTimeBlock(minutes) {
  const duration = moment.duration(minutes, 'minutes')
  const formattedTime = moment.utc(duration.asMilliseconds()).format('HH:mm')
  return formattedTime
}

export default {
  name: 'VeeTimepicker',
  props: {
    value: {
      type: [Array, String],
      default: () => [],
    },
    timeSelectFlag: {
      type: Boolean,
      default: false,
    },
    templateTag: {
      type: String,
      default: () => 'div',
    },
    vid: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    rules: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    isRange: {
      type: Boolean,
      default: () => false,
    },
    format: {
      type: String,
      default: () => 'dd/MM/yyyy',
    },
    noMarginBottom: {
      type: Boolean,
      default: false,
    },
    valueFormat: {
      type: String,
      default: () => 'HH:MM',
    },
    type: {
      type: String,
      default: () => 'date',
    },
    clearable: {
      type: Boolean,
      default: () => false,
    },
    isFlex: {
      type: Boolean,
      default: () => false,
    },
    labelClasses: {
      type: String,
      default: () => '',
    },
    forceStep: {
      type: Number,
    },
    minTime: {
      type: String,
      default: () => '00:00',
    },
    maxTime: {
      type: String,
      default: () => '',
    },
    startTime: {
      type: String,
      default: '00:00',
    },
    endTime: {
      type: String,
      default: '23:59',
    },
    showErrorText:{
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      // valueData: this.value,
    }
  },
  computed: {
    valueData: {
      get() {
        let value = this.$props.value
        if (
          this.clientWorkflowSetting &&
          this.clientWorkflowSetting.enable_timesheet_min_time_block &&
          !this.isRange &&
          value
        ) {
          value = moment(value, this.valueFormat).format('HH:mm')
        }
        return value
      },
      set(value) {
        if (
          this.clientWorkflowSetting &&
          this.clientWorkflowSetting.enable_timesheet_min_time_block &&
          !this.isRange &&
          value
        ) {
          value = moment(value, 'HH:mm').format(this.valueFormat)
        }
        this.$emit('input', value)
      },
    },
    ...mapState({
      client: (state) => state.auth.client,
    }),
    clientWorkflowSetting() {
      return this.client.clientWorkflowSetting
    },
    timesheetMinTimeBlock() {
      let minTimeBlock = 15
      if (this.forceStep) {
        minTimeBlock = this.forceStep
      } else if (this.client && this.client.timesheet_min_time_block) {
        minTimeBlock = this.client.timesheet_min_time_block
      }
      return convertToTimeBlock(minTimeBlock)
    },
  },
  watch: {
    value() {
      this.valueData = this.value
    },
    valueData(value) {
      this.$emit('input', value)
    },
  },
  methods: {
    onChange(value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss">
.el-date-editor .el-range-separator {
  width: auto !important;
  color: #606266 !important;
}
.el-date-editor .el-range-input,
.el-date-editor .el-range-separator {
  text-align: left;
}
.border-radius-4px {
  border-radius: 4px;
}
</style>
