import Vue from "vue";

// Change this config for each app
const Plugin = {};
Plugin.install = function (Vue, options) {
  Vue.prototype.$isInternal = false
  Vue.prototype.$isCustomer = true
  Vue.prototype.$isRecruitment = false
};

Vue.use(Plugin);
