<template>
  <div>
    <div class="row">
      <div class="col-lg-4">
        <!-- <BaseInput v-model="search" prepend-icon="fa fa-search" /> -->
        <div class="form-group mb-0 w-100">
          <div
            class="
              input-group input-group-alternative input-group-merge
            "
          >
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fas fa-search"
              /></span>
            </div>
            <input
              class="form-control"
              v-model="search"
              :placeholder="$t('model.employees.enter_code_name')"
              type="text"
              v-on:keyup.enter="onFilter"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <VeeSelect
          :placeholder="$t('select_employee_group')"
          :options="groupOptions"
          v-model="employeeGroup"
          :disabled="disableGroup"
          filterable
          clearable
        />
      </div>
      <div class="col-lg-3" v-if="showShift">
        <el-select
          class="v-select-single"
          v-model="shiftValue"
        >
          <el-option
            v-for="item in timesheetShifts"
            :key="item.id"
            :label="item.shift_code"
            :value="item.id"
          >
          </el-option>
          <el-option value="is_holiday" :label="$t('holidays')"></el-option>
          <el-option value="" :label="$t('model.timesheets.work_status.weekly_leave')"></el-option>
        </el-select>
      </div>
      <div class="col-lg-2 ml-auto">
        <VerticalButton
            class="float-right"
            icon-class="fa-check"
            @click="save"
            :text="$t('model.buttons.apply')"
            :disabled="iemployees.length === 0"
        />
      </div>
    </div>
    <MasterTable
      response-name="clientEmployees"
      schema="ClientEmployee"
      ref="ClientEmployee"
      :action="gqlAction"
      :filters="filters"
      :table-fields="tableFields"
      @selectAll="selectAll"
    >
      <template v-slot:cell(selected)="{ item }">
        <el-checkbox @mouseup.native="remove(item)" :value="true" v-if="selectedIds.indexOf(item.id) !== -1" class="mb-0" />
        <el-checkbox @mouseup.native="add(item)" :value="false" v-else class="mb-0" />
      </template>

      <template v-slot:cell(avatar)="row">
        <img
            class="avatar bg-white"
            :src="row.item.avatar_path"
            height="50px"
            width="50px"
        />
      </template>
      <template v-slot:head(selected)="data">
        <el-checkbox class="mb-0 hide-el-checkbox-label" label="all" v-model="selectAllItem" />
      </template>
    </MasterTable>
  </div>
</template>
<script>
  import Helper from '@/util/helper'
  import MasterTable from "../MasterTable"

  export default {
    name: "EmployeeSelect",
    components: {MasterTable},
    props: {
      employees: {
        type: Array,
        default: () => []
      },
      clientId: {
        type: String
      },
      closeModal: {
        type: Boolean,
        default: false,
      },
      showShift: {
        type: Boolean,
        default: false,
      },
      timesheetShifts: {
        type: Array,
        default: () => ([])
      },
      gqlAction: {
        type: String,
        default: "get"
      }
    },
    computed: {
      selectedIds() {
        return this.iemployees.map(v => v.id);
      }
    },
    watch: {
      employees() {
        this.iemployees = this.employees;
      },
      // search() {
      //   this.filterSearch = [
      //     "$or",
      //     [
      //       [
      //         "code", "LIKE", "%"+this.search+"%"
      //       ],
      //       [
      //         "full_name", "LIKE", "%"+this.search+"%"
      //       ]
      //     ]
      //   ]
      //   this.filters = [
      //     this.filterClient,
      //     this.filterSearch
      //   ]
      //   if(this.employeeGroup){
      //     this.filters.push(this.filterGroup)
      //   }
      // },
      closeModal() {
        this.reset();
      },
      selectAllItem () {
        if(this.selectAllItem.includes('all')) {
          this.$refs.ClientEmployee.selectAll();
        }else{
          this.iemployees = [];
          this.$emit("update:employees", this.iemployees);
        }
      },
      employeeGroup(idGroup) {
        this.disableGroup = true;
        Helper.gqlQuery("ClientEmployeeGroupAssignment", "get", {
          perPage: 1000,
          page: 1,
          where: {
            AND: [
              { column: "CLIENT_ID", value: this.clientId },
              { column: "CLIENT_EMPLOYEE_GROUP_ID", value: idGroup}
            ]
          }
        }).then(({ data }) => {
          let argsEmployee = [];
          if(data.clientEmployeeGroupAssignments.data.length > 0){
            data.clientEmployeeGroupAssignments.data.map(item => {
              argsEmployee.push([
                "id", "EQ", item.client_employee_id
              ])
            })
          } 
          // else {
          //   argsEmployee.push([
          //     "id", "EQ", 'NaN'
          //   ])
          // }
          if(argsEmployee){
            this.filterGroup = [
              "$or",
              argsEmployee
            ];
            this.filters = [
              this.filterClient,
              this.filterGroup,
              ["status", "đang làm việc"]
            ]
            if(this.search){
              this.filters.push(this.filterSearch)
            }
          }
          this.disableGroup = false;
        });
      },
    },
    data() {
      return {
        search: "",
        employeeGroup: "",
        iemployees: [], // internal employees
        selectAllItem: [],
        groupOptions: [],
        disableGroup : false,
        filterClient : ["client_id", this.clientId],
        filters : [
          ["client_id", this.clientId],  
          ["status", "đang làm việc"]
        ],
        filterSearch : [],
        filterGroup : [],
        tableFields: [
          {
            key: 'selected',
            label: '',
            thStyle: { width: '40px' },
            tdClass: 'text-center',
            sortable: false,
          },
          {
            key: 'avatar',
            label: this.$t('model.employees.avatar'),
            thStyle: { width: '50px' },
          },
          {
            key: 'code',
            label: this.$t('fields.input.code'),
            sortable: true,
            thStyle: { width: '100px' },
            tdClass: 'align-middle',
          },
          {
            key: 'full_name',
            label: this.$t('fields.input.full_name'),
            sortable: false,
            tdClass: 'align-middle',
          },
          {
            key: 'client_department_name',
            label: this.$t('fields.input.department'),
            sortable: true,
            tdClass: 'align-middle',
          },
          {
            key: 'client_position_name',
            label: this.$t('fields.input.position'),
            sortable: true,
            tdClass: 'align-middle',
          },
        ],
        shiftValue: ""
      }
    },
    mounted () {
      this.loadUserGroup();
    },
    methods: {
      onFilter(){
        this.filterSearch = [
          "$or",
          [
            [
              "code", "LIKE", "%"+this.search+"%"
            ],
            [
              "full_name", "LIKE", "%"+this.search+"%"
            ]
          ]
        ]
        this.filters = [
          this.filterClient,
          this.filterSearch
        ]
        if(this.employeeGroup){
          this.filters.push(this.filterGroup)
        }
      },
      loadUserGroup() {
        let where = [
          {column: 'CLIENT_ID', operator: 'EQ', value: this.clientId},
        ]
        Helper.gqlQuery('ClientEmployeeGroup', 'get', {
            perPage: 100,
            page: 1,
            orderBy: [{field: 'CREATED_AT', order: 'ASC'}],
            where: {
                AND: where
            }
        }).then(({data}) => {
          const items = data.clientEmployeeGroups.data
          items.forEach((item) => {
            this.groupOptions.push({label: item.name, value: item.id})
          })
        })
      },
      add(employee) {
        this.iemployees = this.iemployees.concat([employee])
        this.$emit("update:employees", this.iemployees);
      },
      selectAll(items){
        this.iemployees = items;
        this.$emit("update:employees", this.iemployees);
      },
      remove(employee) {
        this.iemployees = this.iemployees.filter(v => v.id !== employee.id)
        this.$emit("update:employees", this.iemployees);
      },
      save() {
        this.$emit("submit", this.iemployees, this.shiftValue);
        this.selectAllItem = [];
        this.search = "";
        this.employeeGroup = "";
        this.filterSearch = [];
        this.filterGroup = [];
      },
      reset() {
        this.selectAllItem = [];
        this.search = "";
        this.employeeGroup = "";
        this.filterSearch = [];
        this.filterGroup = [];
      }
    }
  }
</script>
<style scoped>
  .modal .btn-normal {
    background-color: #2dce89;
    border-color: #2dce89;
  }
</style>
