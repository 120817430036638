import Vue from "vue";
import Swal from "sweetalert2";

// Change this config for each app
const Plugin = {};
Plugin.install = function (Vue, options) {
  Vue.prototype.$showDialog = async function (icon, message, title, callback) {
    Swal.close();
    const modal = Swal.fire({
      title: title,
      text: message,
      icon: icon,
      confirmButtonText: "OK",
    })
    if (callback) {
      modal.then(callback);
    }
    return modal;
  };

  Vue.prototype.$showConfirm = function (message, title, callback) {
    Swal.close();
    const modal = Swal.fire({
      title: title || this.$t('confirm'),
      text: message,
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
    }).then((result) => {
      return result.isConfirmed;
    });
    if (callback instanceof Function) {
      modal.then(callback);
    }
    return modal;
  }

  Vue.prototype.$showAlert = function (message, title, callback) {
    return this.$showDialog("info", message, title, callback);
  };

  Vue.prototype.$showError = function (message, title, callback) {
    this.$showDialog("error", message, title, callback);
  };

  Vue.prototype.$showSuccess = function (message, title, callback) {
    return this.$showDialog(
      "success",
      message || this.$t("model.notifications.success"),
      title,
      callback
    );
  };

  Vue.prototype.$showLoading = async function (message, callback) {
    Swal.close();
    if (!callback) {
      callback = () => {};
    }
    Swal.fire({
      title: message || this.$t("model.social_security_claim.state_processing"),
      text: "",
      icon: "success",
      iconHtml: '<i class="fa fa-spin fa-spinner"></i>',
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: false,
    }).then(callback);
  };
};

Vue.use(Plugin);
