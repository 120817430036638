<template>
  <ValidationProvider
    :vid="name"
    :name="name"
    :rules="rules"
    v-slot="{ errors }"
    :tag="templateTag"
  >
    <div
      class="form-group"
      :class="isFlex ? 'd-flex flex-1 flex-column' : ''"
      :style="noMarginBottom ? 'margin-bottom: 0;' : ''"
    >
      <label
        v-if="label"
        class="form-control-label"
        :class="[isFlex ? 'd-flex flex-1 flex-column' : '', labelClasses]"
        ><strong v-if="required" class="text-danger">*</strong>
        {{ label }}</label
      >
      <div class="has-label">
        <textarea
          v-model="valueData"
          :placeholder="placeholder"
          :required="required"
          :disabled="disabled"
          :error="errors[0]"
          v-bind="$attrs"
          :rows="row"
          class="form-control"
          :class="[
            !showErrorText && errors[0]
              ? 'border border-danger border-radius-4px'
              : '',
          ]"
          @focus="handleFocus"
          @input="mixin_autoResize_resize"
        />
        <div v-if="errors[0] && showErrorText" class="mt-2">
          <small class="text-danger">{{ errors[0] }}</small>
        </div>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
export default {
  name: 'VeeTextarea',
  props: {
    value: {
      type: [String, Number],
      default: () => '',
    },
    rules: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: () => true,
    },
    row: {
      type: String,
      default: () => '3',
    },
    name: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    isFlex: {
      type: Boolean,
      default: () => false,
    },
    labelClasses: {
      type: String,
      default: () => '',
    },
    templateTag: {
      type: String,
      default: () => 'div',
    },
    noMarginBottom: {
      type: Boolean,
      default: false,
    },
    showErrorText: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      valueData: this.value,
    }
  },
  watch: {
    value() {
      this.valueData = this.value
    },
    valueData(value) {
      this.$emit('input', value)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$el.setAttribute('style', 'height', `${this.$el.scrollHeight}px`)
    })
  },
  methods: {
    handleFocus() {
      this.$emit('focus')
    },
    mixin_autoResize_resize(event) {
      event.target.style.height = 'auto'
      event.target.style.height = `${event.target.scrollHeight}px`
      event.target.style.overflow = 'hidden'
    },
  },
}
</script>
<style lang="scss">
.border-radius-4px {
  border-radius: 4px;
}
</style>
