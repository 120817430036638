<template>
  <div class="text-center">
    <img
      v-if="!noIcon"
      class="img-no-data"
      src="/img/theme/no_data.png"
      alt=""
    />
    <div class="lbl-no-data">{{ $t('no_data') }}</div>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    noIcon: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style scoped>
.lbl-no-data {
  color: black;
}
.img-no-data {
  opacity: 0.7;
}
</style>
