var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ValidationProvider',{attrs:{"vid":_vm.name,"name":_vm.name,"rules":_vm.rules,"tag":_vm.templateTag},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"form-group",class:_vm.isFlex ? 'd-flex flex-1 flex-column' : '',style:(_vm.noMarginBottom ? 'margin-bottom: 0;' : '')},[(_vm.label)?_c('label',{staticClass:"form-control-label",class:[_vm.isFlex ? 'd-flex flex-1 flex-column' : '', _vm.labelClasses]},[(_vm.required)?_c('strong',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_vm._v("\n        "+_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"has-label"},[(
            (_vm.clientWorkflowSetting &&
              _vm.clientWorkflowSetting.enable_timesheet_min_time_block &&
              !_vm.isRange) ||
            _vm.timeSelectFlag
          )?_c('el-time-select',_vm._b({class:[
          !_vm.showErrorText && errors[0]
              ? 'border border-danger border-radius-4px'
              : '',
          ],attrs:{"value-format":_vm.valueFormat,"placeholder":_vm.placeholder,"picker-options":{
            step: _vm.timesheetMinTimeBlock,
            start: _vm.startTime,
            end: _vm.endTime,
          },"disabled":_vm.disabled,"format":_vm.format,"clearable":_vm.clearable,"required":_vm.required},on:{"change":_vm.onChange},model:{value:(_vm.valueData),callback:function ($$v) {_vm.valueData=$$v},expression:"valueData"}},'el-time-select',_vm.$attrs,false)):_c('el-time-picker',_vm._b({class:[
          !_vm.showErrorText && errors[0]
              ? 'border border-danger border-radius-4px'
              : '',
          ],attrs:{"is-range":_vm.isRange,"range-separator":_vm.$t('range_time'),"value-format":_vm.valueFormat,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"format":_vm.format,"clearable":_vm.clearable,"required":_vm.required},on:{"change":_vm.onChange},model:{value:(_vm.valueData),callback:function ($$v) {_vm.valueData=$$v},expression:"valueData"}},'el-time-picker',_vm.$attrs,false))],1),_vm._v(" "),(errors[0] && _vm.showErrorText)?_c('div',{staticClass:"mt-2"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]):_vm._e()])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }