const chapterVi = "https://docs.terra-plat.vn/books/huong-dan-su-dung-terra/chapter/"
const pageVi = "https://docs.terra-plat.vn/books/huong-dan-su-dung-terra/page/"

const chapterEn = "https://docs.terra-plat.vn/books/terras-emanual/chapter/"
const pageEn = "https://docs.terra-plat.vn/books/terras-emanual/page/"

const chapterJa = "https://docs.terra-plat.vn/books/terra/chapter/"
const pageJa = "https://docs.terra-plat.vn/books/terra/page/"

const EManualVi = [
    {
        'route': '/ho-so-ca-nhan/thong-tin-co-ban',
        'link': pageVi + 'thong-tin-nhan-vien-ca-nhan-luong-va-bao-hiem'
    },
    {
        'route': '/ho-so-ca-nhan/thong-tin-luong',
        'link': pageVi + 'dang-ky-thong-tin-nguoi-phu-thuoc'
    },
    {
        'route': '/time-sheet',
        'link': pageVi + 'thong-tin-lam-viec-ca-nhan'
    },
    {
        'route': '/dieu-chinh-gio-lam-thuc-te',
        'link': pageVi + 'dieu-chinh-gio-thuc-te-ca-nhan'
    },
    {
        'route': '/dang-ky-cong-so-nghi-phep',
        'link': pageVi + 'dang-ky-nghi-phep-ca-nhan'
    },
    {
        'route': '/dang-ky-cong-so-ot', 
        'link': pageVi + 'dang-ky-lam-them-gio-ca-nhan'
    },
    {
        'route': '/dang-ky-cong-so-cong-tac',
        'link': pageVi + 'dang-ky-di-cong-tac-va-wfh-ca-nhan'
    },
    {
        'route': '/yeu-cau-duyet',
        'link': chapterVi + 'phe-duyet'
    },
    {
        'route': '/nhom-nhan-vien',
        'link': pageVi + 'quan-ly-nhom-nhan-vien'
    },
    {
        'route': '/bang-luong-nhan-vien',
        'link': pageVi + 'xem-phieu-luong-chi-tiet'
    },
    {
        'route': '/social-security-claim',
        'link': pageVi + 'yeu-cau-bao-hiem'
    },
    {
        'route': '/dao-tao',
        'link': pageVi + 'thong-tin-nhan-vien-tham-gia-khoa-dao-tao'
    },
    {
        'route': '/yeu-cau-thanh-toan',
        'link': pageVi + 'yeu-cau-thanh-toan-chi-phi'
    },
    {
        'route': '/nhan-vien',
        'link': pageVi + 'tao-va-update-thong-tin-nhan-vien-admin'
    },
    {
        'route': '/quan-ly-timesheet',
        'link': pageVi + 'quan-ly-thong-tin-va-xuat-bao-cao-lam-viec'
    },
    {
        'route': '/quan-ly-timesheet',
        'link': pageVi + 'quan-ly-thong-tin-va-xuat-bao-cao-lam-viec'
    },
    {
        'route': '/quan-ly-dieu-chinh-gio-lam-thuc-te',
        'link': pageVi + 'quan-ly-dieu-chinh-gio-admin'
    },
    {
        'route': '/quan-ly-dang-ky-cong-so-leave',
        'link': pageVi + 'quan-ly-nghi-phep-admin'
    },
    {
        'route': '/quan-ly-dang-ky-cong-so-ot',
        'link': pageVi + 'quan-ly-lam-them-admin'
    },
    {
        'route': '/quan-ly-dang-ky-cong-so-cong-tac',
        'link': pageVi + 'quan-ly-wfh-admin'
    },
    {
        'route': '/quan-ly-bang-luong-quan-ly',
        'link': pageVi + 'phe-duyet-bang-luong'
    },
    {
        'route': '/quan-ly-khieu-nai-bang-luong',
        'link': pageVi + 'phan-hoi-phieu-luong'
    },
    {
        'route': '/quan-ly-social-security-claim',
        'link': pageVi + 'duyet-ho-so-bhxh'
    },
    {
        'route': '/quan-ly-bao-cao-pit',
        'link': pageVi + 'bao-cao-thue-thu-nhap-ca-nhan'
    },
    {
        'route': '/quan-ly-tin-tuyen-dung',
        'link': chapterVi + 'quan-ly-tuyen-dung'
    },
    {
        'route': '/quan-ly-dao-tao',
        'link': chapterVi + 'quan-ly-dao-tao'
    },
    {
        'route': '/wifi-checkin-spot',
        'link': pageVi + 'quan-ly-cham-cong-wifi'
    },
    {
        'route': '/hanet-ai',
        'link': pageVi + 'quan-ly-cham-cong-camera'
    },
    {
        'route': '/location-checkin',
        'link': pageVi + 'quan-ly-cham-cong-gps'
    },
    {
        'route': '/thiet-lap-dang-ki-cong-so',
        'link': pageVi + 'thiet-lap-nghi-phep'
    },
    {
        'route': '/quan-ly-ca',
        'link': pageVi + 'quan-ly-ca'
    },
    {
      'route': '/quan-ly-ngay-le',
      'link': pageVi + 'quan-ly-ca-ngay-le'
  },
    {
        'route': '/quan-ly-phan-quyen/thiet-lap-flow',
        'link': pageVi + 'quy-trinh-phe-duyet-admin'
    },
    {
        'route': '/quan-ly-phan-quyen/phan-quyen-he-thong',
        'link': pageVi + 'phan-quyen-he-thong-admin'
    },
    {
        'route': '/quan-ly-yeu-cau-thanh-toan',
        'link': chapterVi + 'yeu-cau-thanh-toan'
    },
    {
        'route': '/quan-ly-lich-lam-viec',
        'link': pageVi + 'tao-lich-lam-viec'
    },
    {
        'route': '/timesheet-linh-hoat',
        'link': pageVi + 'cham-cong-linh-hoat'
    },
    {
        'route': '/bang-luong/tao-moi',
        'link': pageVi + 'tao-bang-luong'
    },
    {
        'route': '/quan-ly-mau-tinh-luong',
        'link': pageVi + 'tao-mau-tinh-luong-cong-thuc-bien-f'
    },
    {
        'route': '/quan-ly-template-bang-luong',
        'link': pageVi + 'tao-mau-bang-luong'
    },
    {
        'route': '/quan-ly-dinh-nghia-bien',
        'link': pageVi + 'tao-va-nhap-gia-tri-bien-i'
    },
    {
      'route': ['/quan-ly-phong-ban', '/quan-ly-chuc-vu'],
      'link': pageVi + 'quan-ly-phong-ban-chuc-vu'
    },
    {
      'route': '/ho-so-ca-nhan/thong-tin-tai-khoan',
      'link': chapterVi + 'thong-tin-tai-khoan'
    },
]

const EManualEn = [
    {
        'route': '/ho-so-ca-nhan/thong-tin-co-ban',
        'link': pageEn + 'personal-information'
    },
    {
        'route': '/ho-so-ca-nhan/thong-tin-luong',
        'link': pageEn + 'update-dependant-information'
    },
    {
        'route': ['/time-sheet', '/dang-ky-cong-so-nghi-phep', '/dang-ky-cong-so-ot', '/dang-ky-cong-so-cong-tac'],
        'link': chapterEn + 'working-time'
    },
    {
        'route': '/bang-luong-nhan-vien',
        'link': pageEn + 'view-detailed-payslip'
    },
    {
        'route': '/quan-ly-ke-khai-bao-hiem',
        'link': pageEn + 'social-insurance-declaration'
    },
    {
        'route': '/social-security-claim',
        'link': pageEn + 'claim-insurance'
    },
    {
        'route': '/dao-tao',
        'link': pageEn + 'participating-employees-information'
    },
    {
        'route': '/yeu-cau-thanh-toan',
        'link': pageEn + 'payment-request'
    },
    {
        'route': '/yeu-cau-duyet',
        'link': pageEn + 'notification-of-application-to-be-approved'
    },
    {
        'route': '/nhan-vien',
        'link': pageEn + 'add-and-update-employee-information-admin'
    },
    {
        'route': '/quan-ly-dang-ky-cong-so-ot',
        'link': pageEn + 'overtime-allocation'
    },
    {
        'route': '/quan-ly-bang-luong-quan-ly',
        'link': pageEn + 'approve-payroll'
    },
    {
        'route': '/quan-ly-tin-tuyen-dung',
        'link': chapterEn + 'employee-evaluation-management'
    },
    {
        'route': '/quan-ly-dao-tao',
        'link': chapterEn + 'training-evaluation'
    },
    {
        'route': '/quan-ly-danh-gia',
        'link': chapterEn + 'employee-evaluation-management'
    },
    {
        'route': ['/quan-ly-phan-quyen/thiet-lap-flow', 'quan-ly-phan-quyen/phan-quyen-he-thong'],
        'link': pageEn + 'control-access-user'
    },
    {
        'route': '/quan-ly-yeu-cau-thanh-toan',
        'link': pageEn + 'approve-payment-request'
    },
    {
        'route': '/quan-ly-lich-lam-viec',
        'link': pageEn + 'create-woking-calendar'
    },
    {
        'route': '/timesheet-linh-hoat',
        'link': pageEn + 'flexible-timesheet'
    },
    {
        'route': '/bang-luong/tao-moi',
        'link': pageEn + 'preconditions'
    },
    {
        'route': ['/quan-ly-mau-tinh-luong', '/quan-ly-template-bang-luong'],
        'link': pageEn + 'steps-to-create-a-payroll'
    },
    {
        'route': '/quan-ly-dinh-nghia-bien',
        'link': pageEn + 'steps-to-create-a-payroll'
    },
]

const EManualJa = [
    {
        'route': '/ho-so-ca-nhan/thong-tin-co-ban',
        'link': pageJa + 'kojinjouhou'
    },
    {
        'route': '/ho-so-ca-nhan/thong-tin-luong',
        'link': pageJa + 'fuyoukazokunohenshuu'
    },
    {
        'route': '/time-sheet',
        'link': chapterJa + 'kinmujikan'
    },
    {
        'route': ['/dang-ky-cong-so-nghi-phep', '/dang-ky-cong-so-ot', '/dang-ky-cong-so-cong-tac'],
        'link': chapterJa + 'kinmujikan'
    },
    {
        'route': '/bang-luong-nhan-vien',
        'link': pageJa + 'kyuyomeisai'
    },
    {
        'route': '/social-security-claim',
        'link': pageJa + 'hekenseikyu'
    },
    {
        'route': '/dao-tao',
        'link': pageJa + 'sankashajouhou'
    },
    {
        'route': '/yeu-cau-thanh-toan',
        'link': pageJa + 'keihishinsei'
    },
    {
        'route': '/yeu-cau-duyet',
        'link': pageJa + 'shounintsuchi'
    },
    {
        'route': '/nhan-vien',
        'link': pageJa + 'juugyoinjouhounohenshuujinjibu'
    },
    {
        'route': '/quan-ly-dang-ky-cong-so-ot',
        'link': pageJa + 'zangyowariate'
    },
    {
        'route': '/quan-ly-bang-luong-quan-ly',
        'link': pageJa + 'kyuyoukeisannoshounin'
    },
    {
        'route': '/quan-ly-ke-khai-bao-hiem',
        'link': chapterJa + 'shakaihokenshinkoku'
    },
    {
        'route': '/quan-ly-tin-tuyen-dung',
        'link': chapterJa + 'saiyoukanri'
    },
    {
        'route': '/quan-ly-dao-tao',
        'link': chapterJa + 'kenshuukanri'
    },
    {
        'route': ['/quan-ly-phan-quyen/thiet-lap-flow', 'quan-ly-phan-quyen/phan-quyen-he-thong'],
        'link': pageJa + 'akusesukengenkanri'
    },
    {
        'route': '/quan-ly-yeu-cau-thanh-toan',
        'link': pageJa + 'keihishinsei'
    },
    {
        'route': '/quan-ly-lich-lam-viec',
        'link': pageJa + 'kinmukarendasakusei'
    },
    {
        'route': '/timesheet-linh-hoat',
        'link': pageJa + 'furekkusutaimusei'
    },
    {
        'route': '/bang-luong/tao-moi',
        'link': pageJa + 'senketsujouken'
    },
    {
        'route': ['/quan-ly-mau-tinh-luong', '/quan-ly-template-bang-luong', '/quan-ly-mau-bao-cao-ke-toan'],
        'link': pageJa + 'kyuyoukeisannosakuseitejun'
    },
    {
        'route': '/quan-ly-dinh-nghia-bien',
        'link': pageJa + 'kyuyoukeisannosakuseitejun'
    },
]

export {
    EManualVi,
    EManualEn,
    EManualJa
}
