window.Tawk_API = window.Tawk_API || {};
window.globalTawkAPIReady = false;
Tawk_LoadStart=new Date();
window.Tawk_API.onStatusChange = (status) => {
  window.Tawk_API.minimize()
}
window.Tawk_API.onLoad = () => {
  window.globalTawkAPIReady = true;
}
(function(){
  var s1=document.createElement("script"),
  s0=document.getElementsByTagName("script")[0];
  s1.async=true;
  s1.src='https://embed.tawk.to/60877e0a62662a09efc268bb/1f48k8mkq';
  s1.charset='UTF-8'; s1.setAttribute('crossorigin','*');
  s0.parentNode.insertBefore(s1,s0);
})();
