var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",class:_vm.formGroupClasses,style:(_vm.noMarginBottom ? 'margin-bottom: 0;' : '')},[_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"form-control-label",class:_vm.labelClasses},[(_vm.required)?_c('strong',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]):_vm._e()]}),_vm._v(" "),_c('div',{class:[
      {'input-group': _vm.hasIcon},
      {'focused': _vm.focused},
      {'input-group-alternative': _vm.alternative},
      {'has-label': _vm.label || _vm.$slots.label},
      _vm.inputGroupClasses
    ],style:(_vm.isBreak ? 'flex-basis: 55%;' : '')},[(_vm.prependIcon || _vm.$slots.prepend)?_c('div',{staticClass:"input-group-prepend"},[(!_vm.prependRaw)?_c('div',{staticClass:"input-group-text"},[(_vm.prependIcon)?_c('i',{class:_vm.prependIcon}):_vm._e(),_vm._v(" "),_vm._t("prepend")],2):_vm._t("prepend")],2):_vm._e(),_vm._v(" "),_vm._t("default",function(){return [(_vm.type !== 'number')?_c('input',_vm._g(_vm._b({staticClass:"form-control",class:{invalid: _vm.error},attrs:{"type":_vm.type,"valid":_vm.error,"required":_vm.required},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners)):_c('input',_vm._g(_vm._b({staticClass:"form-control",class:{invalid: _vm.error},attrs:{"type":_vm.type,"valid":_vm.error,"required":_vm.required,"min":_vm.valueMin,"max":_vm.valueMax},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners))]},null,_vm.slotData),_vm._v(" "),(_vm.appendIcon || _vm.$slots.append)?_c('div',{staticClass:"input-group-append"},[(!_vm.appendRaw)?_c('div',{staticClass:"input-group-text"},[(_vm.appendIcon)?_c('i',{class:_vm.appendIcon}):_vm._e(),_vm._v(" "),_vm._t("append")],2):_vm._t("append")],2):_vm._e(),_vm._v(" "),_vm._t("infoBlock")],2),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"mt-2"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error))])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }