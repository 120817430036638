import gql from "graphql-tag";
import api from "~/util/api";
import Helper from "~/util/helper";
import moment from "moment";
import { groupBy, prop } from "ramda";

const getAll = (client_id) => {
  let queryGql = gql`
    query Query(
      $orderBy: [ApproveFlowsAllOrderByOrderByClause!]
      $where: ApproveFlowsAllWhereWhereConditions
    ) {
      approveFlowsAll(orderBy: $orderBy, where: $where) {
        id
        step
        flow_name
        created_at
        updated_at
        approveFlowUsers {
          user {
            id
            code
            name
            username
            clientEmployee {
              full_name
              code
            }
            iGlocalEmployee {
              name
              code
            }
          }
        }
      }
    }
  `;

  const where = { column: "CLIENT_ID", operator: "EQ", value: client_id };
  const orderBy = [{ field: "FLOW_NAME", order: "ASC" }];

  return api
    .query(queryGql, {
      where,
      orderBy,
    })
    .then(({ data }) => {
      if (data.approveFlowsAll) {
        return groupBy(prop("flow_name"))(data.approveFlowsAll);
      }
      return [];
    });
};

const getApproveFlows = (clientId, flowName, groups) => {
  let queryGql = gql`
    query Query($clientId: ID!, $flowName: String!, $groups: [String]) {
      approveFlows(client_id: $clientId, flow_name: $flowName, groups: $groups) {
        id
        step
        flow_name
        created_at
        updated_at
        group_id
        approveFlowUsers {
          user {
            id
            code
            name
            username
            clientEmployee {
              full_name
              code
            }
            iGlocalEmployee {
              name
              code
              role
              assignments {
                client{
                  id,
                  company_name,
                  code
                }
                client_id
              }
            }
            permissions
          }
        }
      }
    }
  `;

  return api
    .query(queryGql, {
      clientId,
      flowName,
      groups
    })
    .then(({ data }) => {
      if (data.approveFlows) {
        return data.approveFlows.sort((a, b) => (a.step < b.step ? -1 : 1));
      }
      return [];
    });
};

const getApproveFlowForMyGroup = (flowName) => {
  let queryGql = gql`
    query Query($flowName: String!) {
      getApproveFlowForMyGroup(flow_name: $flowName) {
        id
        step
        flow_name
        created_at
        updated_at
        group_id
        approveFlowUsers {
          user {
            id
            code
            name
            username
            clientEmployee {
              full_name
              code
            }
            iGlocalEmployee {
              name
              code
            }
          }
        }
      }
    }
  `;

  return api
    .query(queryGql, {
      flowName
    })
    .then(({ data }) => {
      if (data.getApproveFlowForMyGroup) {
        return data.getApproveFlowForMyGroup.sort((a, b) => (a.step < b.step ? -1 : 1));
      }
      return [];
    });
};

const hasPendingApproveRequest = (creatorId, flowName) => {
  let where = [
    { column: "APPROVED_AT", operator: "IS_NULL" },
    { column: "CREATOR_ID", operator: "EQ", value: creatorId },
    { column: "TYPE", operator: "EQ", value: flowName },
    { column: "DECLINED_AT", operator: "IS_NULL" },
  ];

  Helper.gqlQuery("Approve", "get", {
    perPage: 1,
    page: 1,
    where: {
      AND: where,
    },
  }).then(({ data }) => {
    return !!data.approves.data;
  });
};

const getApprovesByTargetId = (targetId) => {
  // TODO handle pagination in the future
  const wheres = [];
  wheres.push({ column: "TARGET_ID", operator: "EQ", value: targetId });
  return Helper.gqlQuery("Approve", "get", {
    perPage: 1000,
    page: 1,
    where: {
      AND: wheres,
    },
  })
    .then((res) => res.data)
    .then((data) => data.approves.data);
};

const getApprovesByTarget = (targetId, type) => {
  // TODO handle pagination in the future
  const wheres = [
    { column: "TYPE", operator: "EQ", value: type },
    { column: "TARGET_ID", operator: "EQ", value: targetId },
  ];

  return Helper.gqlQuery("Approve", "get", {
    perPage: 1000,
    page: 1,
    where: {
      AND: wheres,
    },
  })
    .then((res) => res.data)
    .then((data) => data.approves.data);
};

const getApprovesByGroup = (groupId) => {
  // TODO handle pagination in the future
  const wheres = [
    { column: "APPROVE_GROUP_ID", operator: "EQ", value: groupId },
  ];

  return Helper.gqlQuery("Approve", "get", {
    perPage: 1000,
    page: 1,
    where: {
      AND: wheres,
    },
  })
    .then((res) => res.data)
    .then((data) => data.approves.data);
};

const getApprovesRequestPayment = (groupId) => {
  // TODO handle pagination in the future
  const wheres = [
    { column: "APPROVE_GROUP_ID", operator: "IN", value: groupId },
  ];

  return Helper.gqlQuery("Approve", "get", {
    perPage: 1000,
    page: 1,
    where: {
      AND: wheres,
    },
  })
    .then((res) => res.data)
    .then((data) => data.approves.data);
};

const getHistory = (groupId) => {

  return Helper.gqlQuery("Approve", "getHistory", {
    approve_group_id: groupId
  })
    .then((res) => res.data)
    .then((data) => data.getApproveHistory);
}

const acceptApprove = (id) => {
  return Helper.gqlMutate("Approve", "approve", {
    id: id,
    // TODO xoá param approved_at, lý do: bên backend thật ra không dùng
    approved_at: moment().format("YYYY-MM-DD HH:mm:ss"),
  });
};

const rejectApprove = (id, comment) => {
  return Helper.gqlMutate("Approve", "reject", {
    id: id, 
    comment: comment
  });
};


const declineApprove = (id) => {
  return Helper.gqlMutate("Approve", "delete", {
    id: id,
  });
};

export default {
  getAll,
  getApproveFlows,
  getApproveFlowForMyGroup,
  acceptApprove,
  rejectApprove,
  declineApprove,
  hasPendingApproveRequest,
  getApprovesByTargetId,
  getApprovesByTarget,
  getApprovesByGroup,
  getHistory,
  getApprovesRequestPayment
};
