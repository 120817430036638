import Cookies from 'js-cookie'
import { cookieFromRequest } from '~/util'
import Helper from '@/util/helper'

export const actions = {
  nuxtServerInit ({ commit, context }, { req }) {
    const token = cookieFromRequest(req, 'token')

    if (token) {
      commit('auth/SET_TOKEN', token)
    }

    const locale = cookieFromRequest(req, 'locale')
    if (locale) {
      commit('lang/SET_LOCALE', { locale })
    }
  },

  nuxtClientInit ({ commit, dispatch, context }) {
    const token = Cookies.get('token')

    if (token) {
      commit('auth/SET_TOKEN', token)
    }

    const locale = Cookies.get('locale')
    if (locale) {
      commit('lang/SET_LOCALE', { locale })
    }

  }
}

export const state = () => ({
  locales: ['en', 'vi', 'ja'],
  locale: 'vi'
})

