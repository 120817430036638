import {getRoutePermissions} from "~/router"
export default ({ store, redirect, route }) => {
  if (!store.getters['auth/check']) {
    if (!route.noAuth) {
      return redirect('/dang-nhap')
    }
  } else {
    if (route.path === '/') {
      return redirect('/dashboard')
    }
    const neededPermissions = getRoutePermissions(route.name, store)
    const myPermissions = store.state.auth.permissions

    if (neededPermissions.length) {
      let hasPermission = false
      // OR
      for (let i = 0; i < neededPermissions.length; i++) {
        const permission = neededPermissions[i]
        if (myPermissions.indexOf(permission) !== -1) {
          hasPermission = true
        }
      }
      if (!hasPermission) {
        return redirect({ name: 'dashboard' })
      }
    }
  }
}
