import { render, staticRenderFns } from "./MasterTable.vue?vue&type=template&id=9ca32ed8"
import script from "./MasterTable.vue?vue&type=script&lang=js"
export * from "./MasterTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../customer/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports