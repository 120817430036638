<template>
  <div class="typedatetime" :class="{disabled: disabled}">
    <div v-if="type == 'datetime'" class="d-flex align-items-center">
      <input type="text" v-model="dateTime[0]" class="typedatetime-w2" placeholder="dd" :disabled="disabled"/>
      <span>/</span>
      <input type="text" v-model="dateTime[1]" class="typedatetime-w2 ml-1 mr-1" placeholder="mm" :disabled="disabled"/>
      <span>/</span>
      <input type="text" v-model="dateTime[2]" class="typedatetime-w1" placeholder="yyyy" :disabled="disabled"/>
      
      <div class="d-flex align-items-center ml-1">
        <input type="text" v-model="dateTime[3]" class="typedatetime-w2" placeholder="hh" :disabled="disabled"/>
        <span class="ml-1 mr-1">:</span>
        <input type="text" v-model="dateTime[4]" class="typedatetime-w2" placeholder="mm" :disabled="disabled"/>
      </div>
    </div>
    <div v-else-if="type == 'time'" class="d-flex align-items-center">
      <div class="d-flex align-items-center ml-1">
        <input type="text" v-model="dateTime[3]" class="typedatetime-w2" placeholder="hh" :disabled="disabled"/>
        <span class="ml-1 mr-1">:</span>
        <input type="text" v-model="dateTime[4]" class="typedatetime-w2" placeholder="mm" :disabled="disabled"/>
      </div>
    </div>
    <div v-else>
      <input type="text" v-model="dateTime[0]" class="typedatetime-w2" placeholder="dd" :disabled="disabled"/>
      <span>/</span>
      <input type="text" v-model="dateTime[1]" class="typedatetime-w2 ml-1 mr-1" placeholder="mm" :disabled="disabled"/>
      <span>/</span>
      <input type="text" v-model="dateTime[2]" class="typedatetime-w1" placeholder="yyyy" :disabled="disabled"/>
    </div>
    <div v-if="value != '' && !isValid"><small>Format khong đung</small></div>
  </div>
</template>

<script>
import {reject, is} from 'ramda'

const moment = require('moment')

moment.suppressDeprecationWarnings = true;

export default {
  name: "TypeDatetime",
  props: {
    value: {
      type:  String,
      default: () => ('')
    },
    type: {
      type:  String,
      default: () => ('datetime')
    },
    disabled: {
      type:  Boolean,
      default: () => (false)
    }
  },
  data() {
    return {
      valueData: this.value,
      day: 1,
      month: 1,
      year: 2021,
      hour: 0,
      minute: 0,
      dateTime: ['', '', '', '', ''],
      localTriggerChanged: false
    }
  },
  computed: {
    isValid() {
      let d = this.getValue();

      return moment(d).isValid()
    }
  },
  watch: {
    dateTime(v) {

      if( !this.isValid ) return;
      
      this.localTriggerChanged = true

      let d = this.getValue();
      
      this.$emit('input', d)
    },
    value(v){
      this.setDatetime()
    }
  },
  mounted() {
    this.setDatetime()
  },
  methods: {
    setDatetime() {

      if( !this.value || !moment(this.value).isValid() ) {
        this.dateTime = ['', '', '', '', '']
        return;
      }
      
      let m = moment(this.value)

      switch(this.type){
        case 'datetime':
          this.dateTime = [
            m.format('DD'),
            m.format('MM'),
            m.format('YYYY'),
            m.format('HH'),
            m.format('mm')
          ]
          break;
        case 'time':
          this.dateTime = [
            m.format('DD'),
            m.format('MM'),
            m.format('YYYY'),
            m.format('HH'),
            m.format('mm')
          ]
          break;
        default:
          this.dateTime = [
            m.format('DD'),
            m.format('MM'),
            m.format('YYYY'),
          ]
          break;
      } 
    },
    getValue() {
      let d = '';

      if( this.type == 'datetime' ){
        d = this.dateTime[2] + '-' + this.dateTime[1] + '-' + this.dateTime[0] + ' ' + this.dateTime[3] + ':' + this.dateTime[4] + ':00'
      }else if(this.type == 'time') {
        d = '2021-01-01 ' + this.dateTime[3] + ':' + this.dateTime[4] + ':00'
      }else{
        d = this.dateTime[2] + '-' + this.dateTime[1] + '-' + this.dateTime[0]
      }

      return d;
    }
  }
}
</script>

<style lang="scss">
  .typedatetime {
    float: left;
    border: 1px solid #e4e7ed;
    padding: 4px 10px;
    border-radius: 4px;

    input {
      border: 0;
      outline: none;
    }

    &.disabled {
        background-color: #e9ecef;
        opacity: 1;
        color: #c0c4cc;
        input {
          color: #c0c4cc;
        }
    }

    .typedatetime-w1{
      width: 46px;
      text-align: center;
    }
    .typedatetime-w2{
      width: 30px;
      text-align: center;
    }
  }
  
</style>
