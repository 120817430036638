<template>
  <div v-if="loading" class="backdrop" />
</template>

<script>
import Swal from 'sweetalert2'
export default {
  data: () => ({
    loading: false,
  }),
  methods: {
    start() {
      this.loading = true
      Swal.fire({
        title: this.$t('model.notifications.creating'),
        icon: 'success',
        iconHtml: '<i class="fa fa-spin fa-spinner"></i>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      })
    },
    finish() {
      Swal.close()
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: columns;
  align-items: center;
  justify-content: center;
  .main-logo {
    width: 180px;
    height: 180px;
  }
}
</style>
