<template>
  <VeeSelect
    :label="$t('fields.input.nationality')"
    :options="danhSachQuoctichOptions"
    v-model="valueData"
    :rules="rules"
    :required="required"
    :clearable="false"
  />
</template>
<script>
export default {
  name: "QuocTichSelect",
  props: {
    value: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    nationalityData: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      valueData: this.value,
      modal: false,
      quoctich: "",
    };
  },
  computed: {
    rules() {
      return this.required ? "required" : "";
    },
    danhSachQuoctichOptions() {
      return this.nationalityData.map((item) => ({
        label: item.nationality_name,
        value: item.nationality_name,
      }));
    },
  },
  watch: {
    valueData(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.valueData = newVal;
    },
  },
};
</script>