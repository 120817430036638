import Vue from 'vue'
import TinyMCE from '@tinymce/tinymce-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  Button,
  Card,
  Input,
  CheckboxGroup,
  Checkbox,
  Radio,
  RadioGroup,
  Select,
  Option,
  Tree,
  Dialog,
  Tag,
  Tooltip,
  Popover,
  DatePicker,
  Tabs,
  TabPane,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Steps,
  Step,
  Switch,
  Menu,
  MenuItem,
  MenuItemGroup,
  Submenu,
  Collapse,
  CollapseItem,
  Upload,
  TimeSelect,
  Progress,
  OptionGroup,
  MessageBox,
  Carousel,
  CarouselItem,
  Image
} from 'element-ui'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseDropdown from '@/components/BaseDropdown.vue'
import BaseCard from '@/components/Cards/Card.vue'
import Modal from '@/components/Modal.vue'
import BaseTag from '@/components/BaseTag.vue'
import BaseButton from '@/components/BaseButton.vue'
import Badge from '@/components/Badge.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import BaseSwitch from '@/components/BaseSwitch.vue'
import BaseRadio from '@/components/Inputs/BaseRadio'
import BaseTabs from '@/components/Tabs/Tabs'
import BaseTab from '@/components/Tabs/Tab'
import BaseTable from '@/components/BaseTable'
import BaseProgress from '@/components/BaseProgress'
import BasePagination from '@/components/BasePagination'
import BaseNav from '@/components/Navbar/BaseNav'
import BaseHeader from '@/components/BaseHeader'
import BoxHeader from '@/components/BoxHeader'
import Loading from '@/components/LoadingPanel'
import LoadingGlobal from '@/components/LoadingGlobal'

import TypeDatetime from '@/../frontend-common/components/Inputs/TypeDatetime.vue'
import ElementDatetime from '@/../frontend-common/components/Inputs/ElementDatetime.vue'
import LangSelect from '@/../frontend-common/components/Inputs/LangSelect.vue'
import QuocTichSelect from '@/../frontend-common/components/Inputs/QuocTichSelect.vue'
import DanTocSelect from '@/../frontend-common/components/Inputs/DanTocSelect.vue'

// load no data
import NoData from '@/components/common/Data/NoData'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import 'element-ui/lib/theme-chalk/index.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'jexcel/dist/jexcel.css'
import Notifications from 'vue-notification'

import {
  BModal,
  BButton,
  BSidebar,
  BFormGroup,
  TablePlugin,
  PaginationPlugin,
  FormCheckboxPlugin,
  BFormCheckboxGroup,
  CardPlugin,
  ImagePlugin,
  CollapsePlugin,
  PopoverPlugin,
  FormDatepickerPlugin,
  FormSelectPlugin,
} from 'bootstrap-vue'

import VeeInput from '@/components/Inputs/VeeInput'
import VeeCheckBox from '@/components/Inputs/VeeCheckBox'
import VeeDate from '@/components/Inputs/VeeDate'
import VeeSelect from '@/components/Inputs/VeeSelect'
import VeeSwitch from '@/components/Inputs/VeeSwitch'
import VeeTimepicker from '@/components/Inputs/VeeTimepicker'
import VeeTextarea from '@/components/Inputs/VeeTextarea'
import VeeCurrency from '@frontendCommon/components/Inputs/VeeCurrency'

// accordition
import VueSimpleAccordion from 'vue-simple-accordion'
import 'vue-simple-accordion/dist/vue-simple-accordion.css'

import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'

import TabManager from '@/components/TabManager'

// Frontend common
import MasterTable from '../../frontend-common/components/MasterTable'
import EmployeeSelect from '../../frontend-common/components/Inputs/EmployeeSelect'
import VerticalButton from '../../frontend-common/components/TimesheetShift/VerticalButton'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(faUserSecret)

import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'

library.add(faFontAwesome)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

Vue.component(Badge.name, Badge)
Vue.component(BaseButton.name, BaseButton)
Vue.component(BaseTag.name, BaseTag)
Vue.component(BaseCheckbox.name, BaseCheckbox)
Vue.component(BaseHeader.name, BaseHeader)
Vue.component(BoxHeader.name, BoxHeader)
Vue.component(BaseInput.name, BaseInput)
Vue.component(BaseDropdown.name, BaseDropdown)
Vue.component(BaseNav.name, BaseNav)
Vue.component(BasePagination.name, BasePagination)
Vue.component(BaseProgress.name, BaseProgress)
Vue.component(BaseRadio.name, BaseRadio)
Vue.component(BaseSwitch.name, BaseSwitch)
Vue.component(BaseTabs.name, BaseTabs)
Vue.component(BaseTab.name, BaseTab)
Vue.component(BaseTab.name, BaseTab)
Vue.component(BaseTable.name, BaseTable)
Vue.component(Button.name, Button)
Vue.component(BaseCard.name, BaseCard)
Vue.component(Modal.name, Modal)
Vue.component(Input.name, Input)
Vue.component(Card.name, Card)
Vue.component(Select.name, Select)
Vue.component(OptionGroup.name, OptionGroup)
Vue.component(MessageBox.name, MessageBox)
Vue.component(Option.name, Option)
Vue.component(Dialog.name, Dialog)
Vue.component(Dropdown.name, Dropdown)
Vue.component(DropdownMenu.name, DropdownMenu)
Vue.component(DropdownItem.name, DropdownItem)
Vue.component(Tree.name, Tree)
Vue.component(CheckboxGroup.name, CheckboxGroup)
Vue.component(Checkbox.name, Checkbox)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(Radio.name, Radio)
Vue.component(Tag.name, Tag)
Vue.component(DatePicker.name, DatePicker)
Vue.component(Tabs.name, Tabs)
Vue.component(TabPane.name, TabPane)
Vue.component(Steps.name, Steps)
Vue.component(Step.name, Step)
Vue.component(Switch.name, Switch)
Vue.component(Menu.name, Menu)
Vue.component(MenuItem.name, MenuItem)
Vue.component(MenuItemGroup.name, MenuItemGroup)
Vue.component(Submenu.name, Submenu)
Vue.component(Collapse.name, Collapse)
Vue.component(CollapseItem.name, CollapseItem)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('Treeselect', Treeselect)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-button', BButton)
Vue.component('b-sidebar', BSidebar)
Vue.component('b-modal', BModal)
Vue.component(VeeInput.name, VeeInput)
Vue.component(VeeCheckBox.name, VeeCheckBox)
Vue.component(VeeDate.name, VeeDate)
Vue.component(VeeSelect.name, VeeSelect)
Vue.component(VeeSwitch.name, VeeSwitch)
Vue.component(VeeTimepicker.name, VeeTimepicker)
Vue.component(VeeTextarea.name, VeeTextarea)
Vue.component(VeeCurrency.name, VeeCurrency)
Vue.component('loading', Loading)
Vue.component('loading-global', LoadingGlobal)
Vue.component(NoData.name, NoData)
Vue.component(Upload.name, Upload)
Vue.component(TimeSelect.name, TimeSelect)
Vue.component(TypeDatetime.name, TypeDatetime)
Vue.component(ElementDatetime.name, ElementDatetime)
Vue.component(LangSelect.name, LangSelect)
Vue.component('v-select', vSelect)
Vue.component(MasterTable.name, MasterTable)
Vue.component(EmployeeSelect.name, EmployeeSelect)
Vue.component(VerticalButton.name, VerticalButton)

Vue.component(TabManager.name, TabManager)
Vue.component(QuocTichSelect.name, QuocTichSelect)
Vue.component(DanTocSelect.name, DanTocSelect)

Vue.component(Carousel.name, Carousel)
Vue.component(CarouselItem.name, CarouselItem)
Vue.component(Image.name, Image)

Vue.use(Tooltip)
Vue.use(Popover)
Vue.use(TablePlugin)
Vue.use(PaginationPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(CardPlugin)
Vue.use(ImagePlugin)
Vue.use(CollapsePlugin)
Vue.use(PopoverPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormSelectPlugin)
Vue.use(Notifications)
Vue.use(NoData)
Vue.use(Progress)

Vue.component('TinyMCE', TinyMCE)

/* use accordition */
Vue.use(VueSimpleAccordion)

Vue.directive('precision', {
  inserted(el, binding) {
    let v = binding.value ? binding.value : 0

    v = Math.round(v * 100) / 100

    el.innerHTML = v.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  },
})

Vue.prototype.$confirm = MessageBox.confirm
