<template>
<ValidationProvider :rules="rules" v-slot="{ errors }">
  <div class="type-element-datetime d-flex align-items-center">
    
    <el-date-picker v-model="date"
      format="dd/MM/yyyy" :clearable="false"
      value-format="yyyy-MM-dd"
      type="date" :disabled="disabled"
      placeholder="Date">
    </el-date-picker>

    <time-picker v-model="time" :disabled="disabled"/>
  </div>
  <div v-show="errors[0]"><small class="text-danger mt-2" id="error">{{$t('errors.fields.' + name)}}</small></div>
  <div v-show="customError"><small class="text-danger mt-2" id="error">{{customError}}</small></div>
  </ValidationProvider>
</template>

<script>
import TimePicker from "./TimePicker"

const moment = require('moment')

moment.suppressDeprecationWarnings = true;

export default {
  name: "ElementDatetime",
  components: {
    TimePicker
  },
  props: {
    value: {
      type:  String,
      default: () => ('')
    },
    type: {
      type:  String,
      default: () => ('datetime')
    },
    name: {
      type:  String,
      default: () => ('')
    },
    disabled: {
      type:  Boolean,
      default: () => (false)
    },
    required: {
      type:  Boolean,
      default: () => (false)
    },
    isAfter: {
      type: String,
      default: () => ('')
    },
  },
  data() {
    return {
      valueData: this.value,
      date: '',
      time: '',
      localTriggerChanged: false,
      showPicker: false,
      customError: ''
    }
  },
  computed: {
    rules() {
      return this.required ? 'required' : ''
    },
    isValid() {
      let d = this.getValue();

      let isAfter = true;

      if(this.isAfter){
        isAfter = moment(d).isAfter(moment(this.isAfter))
        
        if(!isAfter) {
          this.customError = this.$t('errors.fields.end_time_is_after_start_time')
        }else{
          this.customError = ''
        }
      }

      return moment(d).isValid() && isAfter;
    }
  },
  watch: {
    date(v) {

      if( !this.isValid ) return;

      this.localTriggerChanged = true

      let d = this.getValue();

      this.$emit('input', d)
    },
    time(v) {

      if( !this.isValid ) return;

      this.localTriggerChanged = true

      let d = this.getValue();

      this.$emit('input', d)
    },
    value(v){
      this.setDatetime()
    },
    isSameOrAfter(v){
      
      this.setDatetime()
    }
  },
  mounted() {
    this.setDatetime()
  },
  methods: {
    setDatetime() {
      
      let m = moment(this.value)

      if( m.isValid() ) {
        
        this.date = m.format('YYYY-MM-DD')
        this.time = m.format('HH:mm')
      }else{
        this.date = ''
        this.time = ''
      }
    },
    getValue() {

      let d = this.date + ' ' + this.time;

      return d;
    }
  }
}
</script>

<style lang="scss">
  .type-element-datetime {
    float: left;
    border: 1px solid #e4e7ed;
    padding: 0;
    border-radius: 4px;

    input {
      border: 0;
      outline: none;
    }

    &.disabled {
        background-color: #e9ecef;
        opacity: 1;
        color: #c0c4cc;
        input {
          color: #c0c4cc;
        }
    }

    .typedatetime-w1{
      width: 46px;
      text-align: center;
    }
    .typedatetime-w2{
      width: 30px;
      text-align: center;
    }
  }



</style>
