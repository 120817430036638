<template>
  <div>
    <b-table
      ref="table"
      :items="loadData"
      :busy.sync="isBusy"
      :selectable="selectable"
      :fields="tableFields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :current-page="currentPage"
      :per-page="perPage"
      show-empty
      striped
      hover
      primary-key="id"
      responsive
      class="thead-light"
    >

      <template v-slot:table-busy>
        <div class="text-center text-dark my-2">
          <strong><i class="fa fa-spin fa-spinner mr-1" />{{ $t('loading_data') }}</strong>
        </div>
      </template>

      <template v-slot:empty="scope">
        <div class="text-center text-danger my-2">
          <NoData />
        </div>
      </template>

      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
    </b-table>
    <div class="row clearfix">
      <div class="col-lg-6">
        <b-pagination
          v-if="itotal"
          v-model="currentPage"
          size="md"
          :total-rows="itotal"
          :per-page="perPage"
          class="mb-0"
        />
      </div>
    </div>
  </div>

</template>

<script>
  import {buildSimpleWhere} from "../utils/GraphQLUtils"
  import {debounce} from "delayed"

  export default {
    name: "MasterTable",
    props: {
      dataCallback: {
        type: Function,
        default() {
          return () => {
          }
        }
      },
      tableFields: {
        type: Array,
        default: () => []
      },
      perPageOptions: {
        type: Array,
        default: () => [10, 20, 50, 100]
      },
      selectable: {
        type: Boolean,
        default: () => false
      },
      total: {
        type: Number,
        default: () => null
      },
      filters: {
        type: Array,
        default: () => []
      },
      schema: {
        type: String,
        default: () => ""
      },
      action: {
        type: String,
        default: () => ""
      },
      variables: {
        type: Object,
        default: () => {}
      },
      responseName: {
        type: String,
        default: () => ""
      }
    },
    watch: {
      filters: {
        deep: true,
        handler() {
          this.debouceLoad();
        }
      },
      total: {
        intermidite: true,
        deep: true,
        handler(value) {
          if (value !== null) {
            this.itotal = total
          }
        }
      }
    },
    created() {
      this.debouceLoad = debounce(() => {
        this.refresh(true)
      }, 500)
    },
    data() {
      return {
        perPage: 20,
        isBusy: false,
        sortBy: null,
        sortDesc: false,
        currentPage: 1,
        itotal: 0,
        debouceLoad: null,
        items : []
      }
    },
    methods: {
      async loadData() {
        this.isBusy = true;
        try {
          const {perPage, currentPage, sortBy, sortDesc, responseName} = this;
          if (this.schema) {
            if (!responseName) {
              return;
            }
            const orderBy = this.buildOrderBy();
            const whereConditions = this.buildWhereConditions();
            const params = {
              first: perPage,
              perPage: perPage,
              page: currentPage,
              orderBy,
              where: whereConditions
            };
            const response = await this.$gqlQuery(this.schema, this.action, params);
            const data = response[responseName] ? response[responseName] : {}
            if (data.paginatorInfo) {
              // is pagination
              const {paginatorInfo} = data
              this.itotal = paginatorInfo.total
              this.$emit('update:total', paginatorInfo.total)
              this.items = data.data || []
            }
            else {
              // is naked data list
              this.items = data || []
            }
          }
          else if (this.dataCallback) {
            this.items = await this.dataCallback(perPage, currentPage, sortBy, sortDesc)
          }
        } catch (err) {
        }
        this.isBusy = false;
        return this.items;
      },
      selectAll(){
        this.$emit('selectAll', this.items)
      },
      buildWhereConditions() {
        if (this.filters.length) {
          return buildSimpleWhere(this.filters);
        }
        return undefined;
      },
      buildOrderBy() {
        if (this.sortBy) {
          return [{field: this.sortBy.toUpperCase(), order: this.sortDesc ? "DESC" : "ASC"}]
        }
        return undefined;
      },
      refresh(resetPage) {
        if (resetPage) {
          this.currentPage = 1;
        }
        this.$refs.table.refresh();
      },
      
    }
  }
</script>
