import Helper from '@/util/helper'

const me = () =>
  Helper.gqlQuery('Auth', 'me')

const logout = () =>
  Helper.gqlMutate('Auth', 'logout')

const setting = () =>
  Helper.gqlQuery('Setting', 'getAll')

export default {
  me,
  logout,
  setting
}
