import { isEmpty } from 'ramda'

export default ({ redirect, store, query }) => {
  const token = query.token ? JSON.parse(query.token) : false;
  const isUserLoggedIn = JSON.parse(
    window.localStorage.getItem('sessions') || '[]'
  )
  const isAddUser = JSON.parse(
    window.sessionStorage.getItem('add_user') || 'false'
  )
  return !isEmpty(isUserLoggedIn) && isAddUser !== true && !token
    ? redirect({ name: 'dashboard' })
    : false
}
