<template>
  <div class="hour-picker" @focusin="showPicker=true" @focusout="hidePicker">
    <div >
      <masked-input class="time-input" :disabled="disabled" v-model="time" mask="11:11" placeholder="hh:mm" @keyup.native="showPicker=false"  />
    </div>
    <div class="picker" v-show="showPicker && !disabled">
      <ul>
        <li v-for="hour in hours" :key="hour" @click="time=hour">{{hour}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
const moment = require('moment')
  import MaskedInput from 'vue-masked-input'

  export default {
    props: {
      value: String,
      disabled: Boolean
    },
    components: {
      MaskedInput
    },
    data() {
        return {
          showPicker: false
        }
    },
    computed: {
      time: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit("input", value)
        }
      },
      hours() {
        const hours = [];
        for(let hour = 0; hour < 24; hour++) {
          hours.push(moment({ hour }).format('HH:mm'));
          hours.push(
              moment({
                hour,
                minute: 30
              }).format('HH:mm')
          );
        }
        return hours;
      }
    },
    methods: {
      hidePicker() {
        setTimeout(() => {
          this.showPicker = false
        }, 200)
      },
    }
  }
</script>

<style lang="scss">
.hour-picker {
  position: relative;

  .picker {
    position: absolute;
    top: 40px;
    height: 150px;
    z-index: 50;
    background: #fff;
    border: 1px solid #efefef;
    border-radius: 6px;
    overflow-y: scroll;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 150px;

      li {
        padding: 6px 10px;
        cursor: pointer;

        &:hover {
          background: #dfdfdf;
        }
      }
    }
  }

  .time-input {
    width: 65px;
    text-align: left;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    padding-top: 4px;
  }
}
</style>
