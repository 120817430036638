<template>
  <base-button
      size="sm"
      type="primary"
      class="d-block"
      style="height: 47px"
      v-bind="$props"
      v-on="$listeners"
  >
    <div style="line-height: 1;">
      <i class="fas font-size-15 p-0" :class="iconClass"></i>
    </div>
    <span v-html="text"></span>
  </base-button>
</template>
<script>
export default {
  name: 'VerticalButton',
  props: {
    iconClass: {
      type: String
    },
    text: {
      type: String,
    },
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean
    }
  }
}
</script>
