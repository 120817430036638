export default ({ store, redirect, route }) => {
  const clientWorkflowSetting = store.state.auth.clientWorkflowSetting
  if(route.name === "dang_ky_cong_so_ot" || route.name === "quan_ly_dang_ky_cong_so_ot") {
    if(!clientWorkflowSetting.enable_overtime_request){
      return redirect({ name: 'home' })
    }
  }

  if(route.name === "dang_ky_cong_so_nghi_phep" || route.name === "quan_ly_dang_ky_cong_so_leave") {
    if(!clientWorkflowSetting.enable_leave_request){
      return redirect({ name: 'home' })
    }
  }
}
