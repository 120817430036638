const buildSimpleWhere = (andConditions) => {
    // sample input
    // [
    //     ["client_id", "94b838b9-597d-452b-9a2b-7917a8cfa181"]
    // ]
    const parse = e => {
        if (e[0] === "$and") {
            return {
                AND: e[1].map(parse)
            }
        }
        if (e[0] === "$or") {
            return {
                OR: e[1].map(parse)
            }
        }

        const op = e[1] && [
            'EQ',
            'NEQ',
            'GT',
            'GTE',
            'LT',
            'LTE',
            'LIKE',
            'NOT_LIKE',
            'IN',
            'NOT_IN',
            'BETWEEN',
            'NOT_BETWEEN',
            'IS_NULL',
            'IS_NOT_NULL'
        ].indexOf(String(e[1]).toUpperCase()) !== -1 ? e[1].toUpperCase() : "EQ"

        return {
            column: e[0].toUpperCase(),
            operator: op,
            value: e[1] === op ? e[2] : e[1]
        }
    }
    let where = {
        AND: andConditions.map(parse)
    };
    return where
}

export {buildSimpleWhere};
